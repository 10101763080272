import HeroComponent from "../../../components/HeroComponent";

const WelcomeArea = ({ data }) => {
  return (
    <section className="hero-section app section-padding" id="home">
      {/* Hero Content */}
      <div className="hero-section-content">
        <div className="container ">
          <div className="row align-items-center">
            {/* Welcome Content */}
            <HeroComponent />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeArea;
