import data from "../../data/static.json";

import Breadcumb from "../../components/Breadcumb";
import TermsAndConditionArea from "../../components/TermsAndConditionArea";

const TermsAndConditionContainer = () => {
  return (
    <>
      <Breadcumb title="Terms and Condition" text="Home" />
      <TermsAndConditionArea
        ClassItem="contact_us_area section-padding-100-0"
        data={data[0].TermsAndConditionArea}
      />
    </>
  );
};

export default TermsAndConditionContainer;
