import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { AiOutlineTwitter } from "react-icons/ai";
import { useAccount } from "wagmi";
import { countUniqueTokens } from "../../../utils";
import { saveAs } from "file-saver";
import {
  desktopPostModalStyles,
  mobilePostModalStyles,
} from "../../../constants/postModal";

import useMediaQuery from "../../../hooks/useMediaQuery";
import LeftArrowIcon from "../../../assets/icons/LeftArrowIcon";
import RightArrowIcon from "../../../assets/icons/RightArrowIcon";
import { readContract } from "@wagmi/core";
import { contractConfig } from "../../../constants/mintModal";
import axios from "axios";
import PostModalMobile from "./PostModalMobile";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";
import { loaderStyles } from "../../../constants/userNftModal";
import CrossIcon from "../../../assets/icons/CrossIcon";
import "./swiper.css";
import Slider from "react-slick";
import { toast } from "react-toastify";
LoadingOverlay.propTypes = undefined;
Modal.setAppElement("#root");

const PostModal = ({
  open,
  onMobilePM,
  closePostModal,
  userModalIsOpen,
  isSuccess,
  callAllFunc,
}) => {
  const { address } = useAccount();
  const [xtraNft, setXtraNft] = useState([]);
  const [onDisplay, setOnDisplay] = useState(0);
  const [nftName, setNftName] = useState("");
  const [isNFTRevealed, setIsNFTRevealed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [slider, setSlider] = useState(null);

  const { isMobile, isSmallerScreen } = useMediaQuery();

  useEffect(() => {
    if (onMobilePM || open) {
      document.body.style.overflow = "hidden";
    }
  }, [open, onMobilePM]);

  useEffect(() => {
    const getNftName = async () => {
      const nftName = await readContract({
        ...contractConfig,
        functionName: "name",
      });
      setNftName(nftName);
    };
    getNftName();
  }, []);

  useEffect(() => {
    const fetchNftData = async () => {
      setIsLoading(true);

      const isNFTRevealedData = await readContract({
        ...contractConfig,
        functionName: "revealed",
      });
      const isNFTRevealed = isNFTRevealedData;
      setIsNFTRevealed(isNFTRevealed);

      const userTokens = await readContract({
        ...contractConfig,
        functionName: "getTokensByAddress",
        args: [address],
      });
      const parsedUserTokens = userTokens.map((token) => Number(token));

      let parsedTokenURIs = [];
      for (const token of parsedUserTokens) {
        const tokenURI = await readContract({
          ...contractConfig,
          functionName: "tokenURI",
          args: [token],
        });
        parsedTokenURIs.push(tokenURI);
      }

      if (isNFTRevealed) {
        try {
          const promises = countUniqueTokens(parsedTokenURIs).map(
            async (uri) => {
              const response = await axios.get(`${uri?.value}`);
              const parsedData = { ...response.data, count: uri?.count };
              return parsedData;
            }
          );
          const parsedNfts = await Promise.all(promises);

          setXtraNft(parsedNfts.flat());
        } catch (error) {
          console.log(error);
        }
      } else {
        setXtraNft(countUniqueTokens(parsedTokenURIs));
      }
      setIsLoading(false);
    };

    fetchNftData();
  }, [address, isSuccess]);

  const handleAllClose = () => {
    closePostModal();
    userModalIsOpen();
  };

  const count = xtraNft[onDisplay]?.count || xtraNft?.count;

  const getFormattedNftName = (item) => {
    if (isNFTRevealed) return item?.attributes[1]?.value;
    else return nftName;
  };

  const closeAllPostModals = () => {
    callAllFunc();
    closePostModal();
  };

  const nextSlide = () => {
    slider.slickNext();
  };

  const previousSlide = () => {
    slider.slickPrev();
  };

  const handleSlideAfterChange = (currentSlide) => {
    setOnDisplay(currentSlide);
  };

  const sliderSettings = {
    className: "center",
    focusOnSelect: true,
    // dots: true,
    infinite: true,
    speed: 500,
    // centerPadding: "100px",
    slidesToShow: 3,
    lazyLoad: true,
    centerMode: true,
    afterChange: handleSlideAfterChange,
  };

  return (
    <>
      <Modal
        isOpen={onMobilePM || open}
        onRequestClose={closeAllPostModals}
        style={
          isLoading
            ? loaderStyles
            : isMobile
            ? mobilePostModalStyles
            : desktopPostModalStyles
        }
        shouldCloseOnOverlayClick={false}
        id="post-modal"
        className="position-absolute overflow-auto bg-white top-50 start-50 translate-middle"
      >
        {isLoading ? (
          <LoadingOverlay
            active={isLoading}
            spinner={<ScaleLoader color="#fff" />}
          >
            <div
              style={{
                height: isMobile ? "250px" : "300px",
                width: isMobile ? "250px" : "300px",
              }}
            ></div>
          </LoadingOverlay>
        ) : isMobile ? (
          <PostModalMobile
            isSmallerScreen={isSmallerScreen}
            handleAllClose={handleAllClose}
            xtraNft={xtraNft}
            count={count}
            setOnDisplay={setOnDisplay}
            onDisplay={onDisplay}
            nftName={nftName}
            getFormattedNftName={getFormattedNftName}
            closePostModal={closePostModal}
            closeAllPostModals={closeAllPostModals}
            handleSlideAfterChange={handleSlideAfterChange}
            nextSlide={nextSlide}
            previousSlide={previousSlide}
            setSlider={setSlider}
          />
        ) : (
          <div
            style={{
              display: "flex",
            }}
          >
            <div onClick={closeAllPostModals}>
              <CrossIcon className="pointer position-absolute right-0 end-0 close-icon" />
            </div>
            <div
              style={{
                flex: "0 0 73%",
                width: "73%",
                padding: "20px",
              }}
            >
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.05)",
                  boxShadow: "0px 10.1885px 31.83px rgba(13, 10, 44, 0.06)",
                  borderRadius: "10px",
                  padding: "20px",
                  height: "100%",
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Slider
                    {...sliderSettings}
                    ref={(slider) => setSlider(slider)}
                  >
                    {xtraNft?.length > 0 &&
                      xtraNft.map((item, index) => (
                        <div>
                          <div key={index}>
                            <div className="position-relative slideContainer">
                              <video
                                className="image video-js"
                                style={{
                                  height: "auto",
                                  width: "50%",
                                  minHeight: "126px",
                                  borderRadius: "10px",
                                }}
                                id="my-video"
                                controls
                                preload="auto"
                                autoPlay
                                loop
                                muted={true}
                              >
                                <source
                                  src={item?.image || `https://${item.value}`}
                                  type="video/mp4"
                                />
                              </video>

                              {count > 1 && index === onDisplay && (
                                <div className="count end-0 top-0 position-absolute rounded-pill">
                                  <span className="fw-light">x</span>
                                  <span>{count}</span>
                                </div>
                              )}
                            </div>
                            <p
                              style={{
                                color: "#000",
                                textAlign: "center",
                                marginBottom: "0px",
                              }}
                            >
                              {getFormattedNftName(item)}
                            </p>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F4F6FE",
                      borderRadius: "100%",
                      height: "42px",
                      width: "7%",
                      textAlign: "center",
                      placeContent: "center",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={previousSlide}
                  >
                    <LeftArrowIcon />
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <button
                      className="btn"
                      style={{ backgroundColor: "#E9F1EE", color: "#027A48" }}
                      onClick={() => {
                        saveAs(
                          xtraNft[onDisplay]?.image ||
                            `https://${xtraNft[onDisplay]?.value}`,
                          getFormattedNftName(xtraNft[onDisplay])
                        );
                        toast.success(
                          `Downloading Xtra ${getFormattedNftName(
                            xtraNft[onDisplay]
                          )}...`
                        );
                      }}
                    >
                      Download
                    </button>
                  </div>

                  <div
                    style={{
                      backgroundColor: "#F4F6FE",
                      borderRadius: "100%",
                      height: "40px",
                      width: "7%",
                      textAlign: "center",
                      placeContent: "center",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={nextSlide}
                  >
                    <RightArrowIcon />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                padding: "20px",
                width: "27%",
                flex: "0 0 27%",
              }}
            >
              <div>
                <h4 style={{ color: "#000", marginTop: "1.7rem" }}>
                  Properties
                </h4>
                <p style={{ color: "#000" }}>
                  {getFormattedNftName(xtraNft[onDisplay]) ||
                    "Fetching data..."}
                  {count > 1 && <span className="fw-bold"> x{count}</span>}
                </p>
              </div>

              <div style={{ marginTop: "30px" }}>
                <h4 style={{ color: "#000", marginTop: "1.7rem" }}>Perks</h4>

                <ul
                  style={{
                    marginTop: "1rem",
                    marginBottom: "2.4rem",
                    listStyle: "inherit",
                  }}
                >
                  <li style={{ listStyle: "inside" }}>Drip your NFT avatars</li>
                  <li style={{ listStyle: "inside" }}>Access 3D files</li>
                  <li style={{ listStyle: "inside" }}>Own the IP</li>
                  <li style={{ listStyle: "inside" }}>Forging Rights</li>
                </ul>
              </div>

              <div className="d-flex flex-column" style={{ marginTop: "40px" }}>
                <button
                  style={{
                    background: " #AA0B22",
                    color: "#fff",
                    padding: "10px",
                    marginBottom: "20px",
                  }}
                  className="btn m-2"
                  onClick={handleAllClose}
                >
                  Mint More
                </button>

                <button
                  style={{
                    color: "#000",
                    padding: "10px",
                    background: "#F4F6FE",
                    boxShadow: " 0px 4.87799px 10px rgba(13, 10, 44, 0.06)",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                  className="btn m-2"
                >
                  <AiOutlineTwitter color=" #00ACEE" />
                  <Link
                    to={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                    target="_blank"
                  >
                    <span style={{ marginLeft: "10px", fontSize: "12px" }}>
                      Share on Twitter
                    </span>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default PostModal;
