import React, { useEffect, useState } from "react";
import { TbCopy } from "react-icons/tb";
import { CopyToClipboard } from "react-copy-to-clipboard";
import success from "../../../assets/img/core-img/success.png";
import fail from "../../../assets/img/core-img/fail.png";
// Modal
import Modal from "react-modal";
import PostModal from "../PostModal/PostModal";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";

import CrossIcon from "../../../assets/icons/CrossIcon";
import useMediaQuery from "../../../hooks/useMediaQuery";

Modal.setAppElement("#root");

LoadingOverlay.propTypes = undefined;

export const OutcomeModal = ({
  outcomeModal,
  outcomeModalMobile,
  closeOutcomeModal,
  closeUserModal,
  closeMintModal,
  txHash,
  loader,
  userModalIsOpen,
  rejected,
  processing,
  error,
  isSuccess,
  errorMessage,
  onPM,
  setOnPM,
  onMobilePM,
  setOnMobilePM,
}) => {
  const [hide, setHide] = useState(false);

  const { isMobile } = useMediaQuery();

  const callAllFunc = () => {
    openPostModal();
    closeOutcomeModal();
    // closeUserModal();
    closeMintModal();
  };
  useEffect(() => {
    setTimeout(() => {
      if (isSuccess) callAllFunc();
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const formatErrorMessage = (error) => {
    if (error?.reason === "execution reverted: Incorrect mint fee") {
      return "Error: Insufficient Balance";
    } else return error?.reason;
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
    content: {
      borderRadius: "10px",
      padding: "15px",
      overflow: "hidden",
      marginTop: isMobile ? "20px" : "55px",
      height: isMobile ? "60vh" : "auto",
      backgroundColor: "#fff",
      outline: "none",
      width: isMobile ? "90vw" : "auto",
    },
  };

  const handleHide = () => {
    setHide(true);

    setTimeout(() => {
      setHide(false);
    }, 3000);
  };

  const openPostModal = () => {
    setOnMobilePM(true);
    setOnPM(true);
  };

  const closePostModal = () => {
    setOnMobilePM(false);

    setOnPM(false);
  };

  const closeWhenNoMint = () => {
    closeOutcomeModal();
  };

  return (
    <div>
      {loader ? (
        <div>
          <Modal
            isOpen={outcomeModal || outcomeModalMobile}
            onRequestClose={closeOutcomeModal}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            className="position-absolute overflow-auto bg-white top-50 start-50 translate-middle"
          >
            {!isMobile ? (
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    background: "#fff",
                    display: "block",
                    height: "20vh",
                  }}
                >
                  <p style={{ color: "#000" }}>Processing transaction...</p>

                  <LoadingOverlay
                    active={loader}
                    spinner={<ScaleLoader color="red" />}
                  />
                </div>

                {txHash !== "" ? (
                  <div style={{ padding: "2rem", height: "30%" }}>
                    <p style={{ color: "#000" }}>Transaction Hash:</p>
                    <div style={{ display: "flex" }}>
                      <a
                        style={{ color: "#005FEE", fontSize: "12px" }}
                        href={`https://etherscan.io/tx/${txHash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {txHash}
                      </a>
                      <CopyToClipboard
                        text={`https://etherscan.io/tx/${txHash}`}
                      >
                        <div onClick={handleHide}>
                          <TbCopy size={20} />
                        </div>
                      </CopyToClipboard>

                      {hide ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Copied.
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <p style={{ color: "#000" }}>Getting transaction hash...</p>
                )}
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    background: "#fff",
                    display: "block",
                    height: "20vh",
                  }}
                >
                  <p style={{ color: "#000" }}>Processing transaction...</p>

                  <LoadingOverlay
                    active={loader}
                    spinner={<ScaleLoader color="red" />}
                  />
                </div>

                {txHash !== "" ? (
                  <div style={{ padding: "2rem", height: "30%" }}>
                    <p style={{ color: "#000" }}>Transaction Hash:</p>
                    <div style={{ display: "flex" }}>
                      <a
                        style={{ color: "#005FEE", fontSize: "12px" }}
                        href={`https://etherscan.io/tx/${txHash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {txHash}
                      </a>
                      <CopyToClipboard
                        text={`https://etherscan.io/tx/${txHash}`}
                      >
                        <div onClick={handleHide}>
                          <TbCopy size={20} />
                        </div>
                      </CopyToClipboard>

                      {hide ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Copied.
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <p style={{ color: "#000" }}>Getting transaction hash...</p>
                )}
              </div>
            )}
          </Modal>
        </div>
      ) : (
        <div>
          <Modal
            isOpen={outcomeModal || outcomeModalMobile}
            onRequestClose={closeOutcomeModal}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            className="position-absolute overflow-auto bg-white top-50 start-50 translate-middle"
          >
            {!isMobile ? (
              <>
                <div
                  style={{ position: "absolute", right: "5%", top: "2%" }}
                  onClick={closeOutcomeModal}
                >
                  <CrossIcon className="pointer" />
                </div>
                {rejected ? (
                  <div style={{ textAlign: "center" }}>
                    <p style={{ color: "#000", padding: "2rem" }}>
                      {error?.reason
                        ? formatErrorMessage(error)
                        : error?.message}
                    </p>

                    <div
                      style={{
                        width: "200px",
                        height: "200px",
                        background: "red",
                        borderRadius: "50%",
                        margin: "1.5rem auto",
                        padding: "1rem",
                        display: "flex",
                        placeContent: "center",
                      }}
                    >
                      <img
                        src={fail}
                        alt="fail"
                        style={{ zIndex: "1", margin: "auto" }}
                      />
                    </div>

                    <div>
                      <button
                        className="btn m-2"
                        style={{ cursor: "pointer" }}
                        onClick={closeWhenNoMint}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <p style={{ color: "#000", padding: "2rem" }}>
                      {isSuccess
                        ? `Transaction Successful.`
                        : `Transaction failed. ${errorMessage}`}
                    </p>

                    <div
                      style={{
                        width: "200px",
                        height: "200px",
                        background: isSuccess ? "#26A212" : "red",
                        borderRadius: "50%",
                        margin: "1.5rem auto",
                        padding: "1rem",
                        display: "flex",
                        placeContent: "center",
                      }}
                    >
                      {!isSuccess ? (
                        <img
                          src={fail}
                          alt="fail"
                          style={{ zIndex: "1", margin: "auto" }}
                        />
                      ) : (
                        <img
                          src={success}
                          alt="success"
                          style={{ zIndex: "1", margin: "auto" }}
                        />
                      )}
                    </div>

                    <div style={{ padding: "2rem" }}>
                      <p style={{ color: "#000" }}>Transaction Hash:</p>
                      <div style={{ display: "flex" }}>
                        <a
                          style={{ color: "#005FEE", fontSize: "12px" }}
                          href={`https://etherscan.io/tx/${txHash}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {txHash}
                        </a>

                        <CopyToClipboard
                          text={`https://etherscan.io/tx/${txHash}`}
                        >
                          <div onClick={handleHide}>
                            <TbCopy size={20} />
                          </div>
                        </CopyToClipboard>

                        {hide ? (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            Copied.
                          </span>
                        ) : null}
                      </div>
                    </div>

                    {!isSuccess ? null : (
                      <div style={{ marginTop: "10px" }}>
                        <span
                          className="m-2 "
                          style={{
                            // cursor: "pointer",
                            color: "#000",
                            // backgroundColor: "#aa0b22",
                          }}
                          onClick={callAllFunc}
                        >
                          Fetching minted NFT...
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                <div
                  style={{ position: "absolute", right: "5%", top: "2%" }}
                  onClick={closeOutcomeModal}
                >
                  <CrossIcon className="pointer" />
                </div>
                {rejected ? (
                  <div style={{ textAlign: "center" }}>
                    <p style={{ color: "#000", padding: "2rem" }}>
                      {error?.reason
                        ? formatErrorMessage(error)
                        : error?.message}
                    </p>

                    <div
                      style={{
                        width: "200px",
                        height: "200px",
                        background: "red",
                        borderRadius: "50%",
                        margin: "1.5rem auto",
                        padding: "1rem",
                        display: "flex",
                        placeContent: "center",
                      }}
                    >
                      <img
                        src={fail}
                        alt="fail"
                        // width={"100%"}
                        style={{ zIndex: "1", margin: "auto" }}
                      />
                    </div>

                    <div>
                      <button
                        className="btn m-2"
                        style={{ cursor: "pointer" }}
                        onClick={closeWhenNoMint}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <p style={{ color: "#000", padding: "2rem" }}>
                      {isSuccess
                        ? `Transaction Successful.`
                        : `Transaction failed. ${errorMessage}`}
                    </p>

                    <div
                      style={{
                        width: "200px",
                        height: "200px",
                        background: isSuccess ? "#26A212" : "red",
                        borderRadius: "50%",
                        margin: "1.5rem auto",
                        padding: "1rem",
                        display: "flex",
                        placeContent: "center",
                      }}
                    >
                      {!isSuccess ? (
                        <img
                          src={fail}
                          alt="fail"
                          style={{ zIndex: "1", margin: "auto" }}
                        />
                      ) : (
                        <img
                          src={success}
                          alt="success"
                          style={{ zIndex: "1", margin: "auto" }}
                        />
                      )}
                    </div>

                    <div style={{ padding: "2rem" }}>
                      <p style={{ color: "#000" }}>Transaction Hash:</p>
                      <div style={{ display: "flex" }}>
                        <a
                          style={{ color: "#005FEE", fontSize: "12px" }}
                          href={`https://etherscan.io/tx/${txHash}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {txHash}
                        </a>

                        <CopyToClipboard
                          text={`https://etherscan.io/tx/${txHash}`}
                        >
                          <div onClick={handleHide}>
                            <TbCopy size={20} />
                          </div>
                        </CopyToClipboard>

                        {hide ? (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            Copied.
                          </span>
                        ) : null}
                      </div>
                    </div>

                    {!isSuccess ? null : (
                      <div style={{ marginTop: "10px" }}>
                        <span
                          className="m-2"
                          style={{
                            color: "#fff",
                          }}
                          onClick={callAllFunc}
                        >
                          Fetching minted NFT...
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </Modal>
        </div>
      )}

      <PostModal
        open={onPM}
        onMobilePM={onMobilePM}
        closePostModal={closePostModal}
        userModalIsOpen={userModalIsOpen}
        isSuccess={isSuccess}
        callAllFunc={callAllFunc}
      />
    </div>
  );
};
