import TermsAndConditionContainer from "../containers/TermsAndCondition";
import Layout from "../layouts";

const Contact = () => {
  return (
    <Layout Title="Contact">
      <TermsAndConditionContainer />
    </Layout>
  );
};

export default Contact;
