import React from "react";
import Layout from "../layouts";
import Breadcumb from "../components/Breadcumb";

const PageNotFound = () => {
  return (
    <Layout Title="Page Not Found">
      <Breadcumb
        title="Page Not Found"
        addOtherTitle="404"
        text="Home"
        link="/"
      />
    </Layout>
  );
};

export default PageNotFound;
