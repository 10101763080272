import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineTwitter } from "react-icons/ai";
import LeftArrowIcon from "../../../assets/icons/LeftArrowIcon";
import RightArrowIcon from "../../../assets/icons/RightArrowIcon";
import { saveAs } from "file-saver";

import Slider from "react-slick";
import CrossIcon from "../../../assets/icons/CrossIcon";
import scroll from "../../../assets/fast-forward.gif";
import { toast } from "react-toastify";

const PostModalMobile = ({
  xtraNft,
  handleAllClose,
  isSmallerScreen,
  count,
  onDisplay,
  getFormattedNftName,
  closeAllPostModals,
  handleSlideAfterChange,
  previousSlide,
  nextSlide,
  setSlider,
}) => {
  const sliderSettings = {
    // focusOnSelect: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    lazyLoad: true,

    afterChange: handleSlideAfterChange,
  };

  const scrollDown = () => {
    const modalContainer = document.getElementById("post-modal");
    modalContainer.scrollTop = modalContainer.scrollHeight;
  };

  return (
    <>
      <div className="position-relative" onClick={closeAllPostModals}>
        <CrossIcon className="pointer position-absolute end-0" />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          rotate: "90deg",
          margin: "10px",
        }}
        onClick={scrollDown}
      >
        <img
          src={scroll}
          style={{ position: "sticky" }}
          width="30px"
          alt="down"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: isSmallerScreen ? "column" : "row",
          gap: "10px",
          margin: "20px 0px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: isSmallerScreen ? "0 0 100%" : "0 0 60%",
            width: isSmallerScreen ? "100%" : "60%",
          }}
        >
          <div
            style={{
              display: "flex",
              background: "rgba(255, 255, 255, 0.05)",
              boxShadow: "0px 3.61464px 11.2958px rgba(13, 10, 44, 0.06)",
              borderRadius: "3.44252px",
              flexDirection: "column",
              justifyContent: "space-between",

              overflow: "hidden",
              padding: "10px",
            }}
          >
            <div>
              <Slider
                {...sliderSettings}
                ref={(slider) => setSlider(slider)}
                className="slider2"
              >
                {xtraNft?.length > 0 &&
                  xtraNft.map((item, index) => (
                    <div key={index}>
                      <div
                        className="position-relative "
                        style={{ width: "100%", height: "100%" }}
                      >
                        <video
                          className="image video-js"
                          style={{
                            height: "auto",
                            width: "100%",
                            borderRadius: "10px",
                          }}
                          id="my-video"
                          controls
                          preload="auto"
                          autoPlay
                          loop
                          muted={index === onDisplay ? false : true}
                        >
                          <source
                            src={item?.image || `https://${item.value}`}
                            type="video/mp4"
                          />
                        </video>

                        {count > 1 && index === onDisplay && (
                          <div className="bg-white px-2 fs-6 m-2 end-0 top-0 position-absolute rounded-pill">
                            <span className="fw-light">x</span>
                            {count}
                          </div>
                        )}
                      </div>
                      <p
                        style={{
                          color: "#000",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        {getFormattedNftName(item)}{" "}
                        {count > 1 && (
                          <span className="fw-bold"> x{count}</span>
                        )}
                      </p>
                    </div>
                  ))}
              </Slider>
            </div>

            <div className="d-flex justify-content-between">
              <div onClick={previousSlide}>
                <LeftArrowIcon />
              </div>
              <div className="bg-grey" onClick={nextSlide}>
                <RightArrowIcon />
              </div>
            </div>
          </div>
          <div
            style={{
              background: "#E9F1EE",
              borderRadius: "1.71808px",
              color: "#027A48 ",
              textAlign: "center",
              padding: "10px",
              marginTop: "20px",
            }}
            onClick={() => {
              saveAs(
                xtraNft[onDisplay]?.image ||
                  `https://${xtraNft[onDisplay]?.value}`,
                getFormattedNftName(xtraNft[onDisplay])
              );
              toast.success(
                `Downloading Xtra ${getFormattedNftName(xtraNft[onDisplay])}...`
              );
            }}
          >
            Download
          </div>
        </div>

        <div
          style={{
            padding: "10px",
            width: isSmallerScreen ? "100%" : "35%",
            textAlign: isSmallerScreen ? "center" : "",
          }}
        >
          <div>
            <h4 style={{ color: "#000", marginTop: "1.7rem" }}>Properties</h4>
            <p style={{ color: "#000" }}>
              {getFormattedNftName(xtraNft[onDisplay]) || "Fetching data..."}
              {count > 1 && <span className="fw-bold"> x{count}</span>}
            </p>
          </div>

          <div style={{ marginTop: "30px" }}>
            <h4 style={{ color: "#000", marginTop: "1.7rem" }}>Perks</h4>

            <ul
              style={{
                marginTop: "1rem",
                marginBottom: "2.4rem",
                listStyle: "inherit",
              }}
            >
              <li>Drip your NFT avatars</li>
              <li>Access 3D files</li>
              <li>Own the IP</li>
              <li>Forging Rights</li>
            </ul>
          </div>

          <div style={{ marginTop: isSmallerScreen ? "30px" : "90px" }}>
            <button
              style={{
                background: " #AA0B22",
                color: "#fff",
                padding: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
              className="btn"
              onClick={handleAllClose}
            >
              Mint More
            </button>

            <button
              style={{
                color: "#000",
                padding: "10px",
                margin: "0px 0px 15px",
                background: "#F4F6FE",
                boxShadow: "0px 1.67615px 3.43615px rgba(13, 10, 44, 0.06)",
                borderRadius: "3.43615px",
                width: "100%",
              }}
              className="btn "
            >
              <AiOutlineTwitter color=" #00ACEE" />
              <Link
                to={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                target="_blank"
              >
                <span style={{ marginLeft: "10px", fontSize: "12px" }}>
                  Share on Twitter
                </span>
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostModalMobile;
