import React, { useState } from "react";

import { Link } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import CrossIcon from "../../../assets/icons/CrossIcon";
import LeftArrowIcon from "../../../assets/icons/LeftArrowIcon";
import RightArrowIcon from "../../../assets/icons/RightArrowIcon";
import { saveAs } from "file-saver";
import MyConnectWallet from "../../ConnectButton";
import styles from "./style.module.css";
import { FiAlertCircle } from "react-icons/fi";

import "./slider2.css";
import Slider from "react-slick";
import { toast } from "react-toastify";

const EmptyAuthorizedNFTModal = ({
  closeModal,
  dummyImages,
  address,
  show,
  setShow,
  discord,
  setDiscord,
  scriptURI,
  isMobile,
  publicSale,
  openMintModal,
  isConnected,
}) => {
  const [slider, setSlider] = useState(null);
  const nextSlide = () => {
    slider.slickNext();
  };

  const previousSlide = () => {
    slider.slickPrev();
  };
  const sliderSettings = {
    infinite: false,
    speed: 500,

    slidesToShow: 1,

    lazyLoad: true,
  };

  return isMobile ? (
    <>
      <div className="d-flex position-sticky justify-content-between align-center">
        <p className="text-black" style={{ color: "red" }}>
          No authorized nft found
        </p>

        <div onClick={closeModal}>
          <CrossIcon className="pointer position-absolute end-0" />
        </div>
      </div>

      <div className="mobile_res_modal d-lg-none d-block">
        <div className="" style={{ height: "25vh" }}>
          <div className="row my-3">
            {dummyImages
              .map((item, i) => {
                return (
                  <div className="col-6 text-center" key={i}>
                    <img src={item} alt="item" />
                    <p
                      style={{
                        color: "#027A48",
                        cursor: "pointer",
                        marginBottom: "0px",
                      }}
                      onClick={(e) => {
                        toast.success(`Downloading Xtra${i + 1}...`);
                        saveAs(item, `Xtra${i + 1}`);
                      }}
                    >
                      Download
                    </p>
                  </div>
                );
              })
              .slice(0, 2)}
          </div>

          <div className="row my-3">
            {dummyImages
              .map((item, i) => {
                return (
                  <div className="col-6 text-center" key={i}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <img
                        src={item}
                        alt="item"
                        style={{
                          opacity: show || !!address ? "1" : "0.1",
                        }}
                      />

                      {show === true ? (
                        <p
                          style={{
                            color: "#027A48",
                            cursor: "pointer",
                            display: "block",
                            marginBottom: "0px",
                          }}
                          onClick={
                            show
                              ? (e) => {
                                  saveAs(item, `Xtra${i + 1}`);
                                  toast.success(`Downloading Xtra${i + 1}...`);
                                }
                              : null
                          }
                        >
                          Download
                        </p>
                      ) : null}

                      {show !== true ? (
                        <div
                          style={{
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            left: "50%",
                            top: "50%",
                          }}
                        >
                          <a
                            rel="noreferrer"
                            onClick={() => {
                              setShow(true);
                            }}
                            className={`twitter-share-button ${styles.shareLink}`}
                            style={{
                              display: !!address ? "none" : "block",
                              background: "#3498db",
                            }}
                            target="_blank"
                            href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                          >
                            Tweet
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })
              .slice(2, 3)}

            {dummyImages
              .map((item, i) => {
                return (
                  <div className="col-6 text-center" key={i}>
                    <img
                      src={`${item}`}
                      alt="full"
                      loading="lazy"
                      className="lazyload"
                      onError={(e) => (e.target.src = dummyImages[i])}
                      style={{
                        opacity: discord || !!address ? "1" : "0.1",
                      }}
                    />

                    {discord === true ? (
                      <p
                        style={{
                          color: "#027A48",
                          cursor: "pointer",
                          display: "block",
                          marginBottom: "0px",
                        }}
                        onClick={
                          discord
                            ? (e) => {
                                saveAs(item, `Xtra${i + 1}`);
                                toast.success(`Downloading Xtra${i + 1}...`);
                              }
                            : null
                        }
                      >
                        Download
                      </p>
                    ) : null}
                    {show !== true ? (
                      <a
                        rel="noreferrer"
                        onClick={() => {
                          setShow(true);
                        }}
                        className={`twitter-share-button ${styles.shareLink}`}
                        style={{
                          display: !!address ? "none" : "block",
                          background: "#3498db",
                        }}
                        target="_blank"
                        href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                      >
                        Tweet
                      </a>
                    ) : null}

                    {show === true ? (
                      <a
                        rel="noreferrer"
                        onClick={() => {
                          setDiscord(true);
                        }}
                        style={{
                          background: "#7934d9",
                          display: discord ? "none" : "block",
                        }}
                        target="_blank"
                        className={`twitter-share-button ${styles.shareLink}`}
                        href={`https://discord.gg/RXh9sTKake`}
                      >
                        Discord
                      </a>
                    ) : null}
                  </div>
                );
              })
              .slice(3, 4)}
          </div>

          <div className="row my-3">
            {dummyImages
              .map((item, i) => {
                return (
                  <div className="col-6 text-center" key={i}>
                    <img
                      src={`${item}`}
                      alt="full"
                      loading="lazy"
                      className="lazyload"
                      onError={(e) => (e.target.src = dummyImages[i])}
                      style={{
                        opacity: !!address ? "1" : "0.1",
                      }}
                    />

                    {show !== true ? (
                      <a
                        rel="noreferrer"
                        onClick={() => {
                          setShow(true);
                        }}
                        className={`twitter-share-button ${styles.shareLink}`}
                        style={{
                          display: !!address ? "none" : "block",
                          background: "#3498db",
                        }}
                        target="_blank"
                        href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                      >
                        Tweet
                      </a>
                    ) : null}

                    {show === true ? (
                      discord ? (
                        <div>
                          <MyConnectWallet
                            style={{
                              background: "#7934d9",
                              border: "none",
                              display: !!address ? "none" : "block",
                            }}
                            className={`twitter-share-button ${styles.shareLink}`}
                            icon={false}
                            renderSecondaryBtn={false}
                          />
                        </div>
                      ) : (
                        <a
                          rel="noreferrer"
                          onClick={() => {
                            setDiscord(true);
                          }}
                          style={{
                            background: "#7934d9",
                            display: !!address ? "none" : "block",
                          }}
                          target="_blank"
                          className={`twitter-share-button ${styles.shareLink}`}
                          href={`https://discord.gg/RXh9sTKake`}
                        >
                          Discord
                        </a>
                      )
                    ) : null}

                    {address && (
                      <div>
                        <a
                          style={{
                            color: "#027A48",
                            cursor: "pointer",
                            display: "block",
                            marginBottom: "0px",
                          }}
                          onClick={
                            !!address
                              ? (e) => {
                                  saveAs(item, `Xtra${i + 1}`);
                                  toast.success(`Downloading Xtra${i + 1}...`);
                                }
                              : null
                          }
                          href={item}
                          download
                          target={"_self"}
                        >
                          Download
                        </a>
                      </div>
                    )}
                  </div>
                );
              })
              .slice(4, 6)}
          </div>

          <div className="row my-3">
            {dummyImages
              .map((item, i) => {
                return (
                  <div className="col-6 text-center" key={i}>
                    <img
                      src={`${item}`}
                      alt="full"
                      loading="lazy"
                      className="lazyload"
                      onError={(e) => (e.target.src = dummyImages[i])}
                      style={{
                        opacity: !!address ? "1" : "0.1",
                      }}
                    />

                    {show !== true ? (
                      <a
                        rel="noreferrer"
                        onClick={() => {
                          setShow(true);
                        }}
                        className={`twitter-share-button ${styles.shareLink}`}
                        style={{
                          display: !!address ? "none" : "block",
                          background: "#3498db",
                        }}
                        target="_blank"
                        href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                      >
                        Tweet
                      </a>
                    ) : null}

                    {show === true ? (
                      discord ? (
                        <div>
                          <MyConnectWallet
                            style={{
                              background: "#7934d9",
                              border: "none",
                              display: !!address ? "none" : "block",
                            }}
                            className={`twitter-share-button ${styles.shareLink}`}
                            icon={false}
                            renderSecondaryBtn={false}
                          />
                        </div>
                      ) : (
                        <a
                          rel="noreferrer"
                          onClick={() => {
                            setDiscord(true);
                          }}
                          style={{
                            background: "#7934d9",
                            display: !!address ? "none" : "block",
                          }}
                          target="_blank"
                          className={`twitter-share-button ${styles.shareLink}`}
                          href={`https://discord.gg/RXh9sTKake`}
                        >
                          Discord
                        </a>
                      )
                    ) : null}
                    {address && (
                      <div>
                        <a
                          style={{
                            color: "#027A48",
                            cursor: "pointer",
                            display: "block",
                            marginBottom: "0px",
                          }}
                          onClick={
                            !!address
                              ? (e) => {
                                  saveAs(item, `Xtra${i + 1}`);
                                  toast.success(`Downloading Xtra${i + 1}...`);
                                }
                              : null
                          }
                          href={item}
                          download
                          target={"_self"}
                        >
                          Download
                        </a>
                      </div>
                    )}
                  </div>
                );
              })
              .slice(6, 8)}
          </div>
          <div
            style={{
              marginTop: "20px",
              padding: "10px 5px",
              position: "sticky",
              bottom: "-3%",
              backgroundColor: "#fff",
              zIndex: 1,
            }}
            className="text-center"
          >
            {publicSale ? (
              isConnected ? (
                // The magic blocker for the minting process -  address === inputValue
                <button
                  className="btn"
                  style={{
                    color: "#fff",
                    backgroundColor: "#aa0b22",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openMintModal();
                  }}
                >
                  Mint XTRA Jackets
                </button>
              ) : (
                <ConnectButton.Custom>
                  {({
                    account,
                    chain,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                  }) => {
                    // Note: If your app doesn't use authentication, you
                    // can remove all 'authenticationStatus' checks
                    const ready = mounted && authenticationStatus !== "loading";
                    const connected =
                      ready &&
                      account &&
                      chain &&
                      (!authenticationStatus ||
                        authenticationStatus === "authenticated");

                    return (
                      <div
                        {...(!ready && {
                          "aria-hidden": true,
                          style: {
                            opacity: 0,
                            pointerEvents: "none",
                            userSelect: "none",
                          },
                        })}
                      >
                        {(() => {
                          if (!connected) {
                            return (
                              <button
                                onClick={openConnectModal}
                                type="button"
                                className="btn "
                                style={{
                                  color: "#fff",
                                  backgroundColor: "#aa0b22",
                                  cursor: "pointer",
                                }}
                              >
                                Connect Wallet to Mint
                              </button>
                            );
                          }
                        })()}
                      </div>
                    );
                  }}
                </ConnectButton.Custom>
              )
            ) : (
              <button className="btn" style={{ backgroundColor: "#aa0b22" }}>
                <a
                  href="https://opensea.io/collection/xtra-fantasy-pyramid"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#fff" }}
                >
                  Get XTRA Fantasy Pyramid
                </a>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="">
      <div className={styles.cancel__wrapper} onClick={closeModal}>
        <CrossIcon className="pointer position-absolute top-0 end-0 m-3" />
      </div>

      <div style={{ textAlign: "center", width: "100%" }}>
        <p style={{ color: "#000" }}>
          <FiAlertCircle size={16} color="red" style={{ marginRight: "5px" }} />
          OOPS! You have no supported NFTs in this address.
        </p>

        <div
          style={{
            background: "rgba(244, 246, 254, 0.8)",
            display: "flex",
            alignItems: "center",
            padding: "0px",
            justifyContent: "space-between",
          }}
        >
          {
            <div style={{ width: "100%" }}>
              <Slider
                {...sliderSettings}
                ref={(slider) => setSlider(slider)}
                className="slider2"
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      width: "100%",
                      alignItems: "stretch",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                    className="slider2"
                  >
                    {dummyImages
                      ?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                onError={(e) => (e.target.src = dummyImages[i])}
                                loading="lazy"
                                className={styles.nftImage}
                              />
                            </div>
                            <p
                              style={{
                                color: "#027A48",
                                cursor: "pointer",
                                marginBottom: "0px",
                              }}
                              onClick={(e) => {
                                toast.success(`Downloading Xtra${i + 1}...`);
                                saveAs(item, `Xtra${i + 1}`);
                              }}
                            >
                              Download
                            </p>
                          </div>
                        );
                      })
                      .slice(0, 2)}

                    {dummyImages
                      ?.map((item, i) => {
                        return (
                          <div
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                            key={i}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                loading="lazy"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = scriptURI[i])}
                                style={{
                                  opacity: show || !!address ? "1" : "0.1",
                                }}
                              />
                            </div>
                            {show !== true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setShow(true);
                                }}
                                style={{
                                  background: "#3498db",
                                  display: !!address ? "none" : "block",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                              >
                                Tweet
                              </a>
                            ) : null}
                            {show === true || !!address ? (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  show || !!address
                                    ? (e) => {
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                              >
                                Download
                              </p>
                            ) : null}
                          </div>
                        );
                      })
                      .slice(2, 3)}
                    {dummyImages
                      ?.map((item, i) => {
                        return (
                          <div
                            className="slider2"
                            key={i}
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                loading="lazy"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = scriptURI[i])}
                                style={{
                                  opacity: discord || !!address ? "1" : "0.1",
                                }}
                              />
                            </div>
                            {show !== true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setShow(true);
                                }}
                                style={{
                                  background: "#3498db",
                                  display: !!address ? "none" : "block",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                              >
                                Tweet
                              </a>
                            ) : null}
                            {show === true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setDiscord(true);
                                }}
                                style={{
                                  background: "#7934d9",
                                  display: discord ? "none" : "block",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://discord.gg/RXh9sTKake`}
                              >
                                Discord
                              </a>
                            ) : null}
                            {discord === true || !!address ? (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  discord || !!address
                                    ? (e) => {
                                        // console.log(item);
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                              >
                                Download
                              </p>
                            ) : null}
                          </div>
                        );
                      })
                      .slice(3, 4)}
                  </div>
                </div>

                <div className="slider2">
                  <div className={styles.swiperSlidesContainer}>
                    {dummyImages
                      ?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                style={{
                                  opacity: !!address ? "1" : "0.1",
                                }}
                                loading="lazy"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = scriptURI[i])}
                              />
                            </div>
                            {show !== true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setShow(true);
                                }}
                                className={`twitter-share-button ${styles.shareLink}`}
                                style={{
                                  display: !!address ? "none" : "block",
                                  background: "#3498db",
                                }}
                                target="_blank"
                                href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                              >
                                Tweet
                              </a>
                            ) : null}
                            {show === true ? (
                              discord ? (
                                <div>
                                  <MyConnectWallet
                                    style={{
                                      background: "#7934d9",
                                      border: "none",
                                      display: !!address ? "none" : "block",
                                      width: "140px",
                                    }}
                                    className={`twitter-share-button ${styles.shareLink}`}
                                    icon={false}
                                    renderSecondaryBtn={false}
                                  />
                                </div>
                              ) : (
                                <a
                                  rel="noreferrer"
                                  onClick={() => {
                                    setDiscord(true);
                                  }}
                                  style={{
                                    background: "#7934d9",
                                    display: !!address ? "none" : "block",
                                  }}
                                  target="_blank"
                                  className={`twitter-share-button ${styles.shareLink}`}
                                  href={`https://discord.gg/RXh9sTKake`}
                                >
                                  Discord
                                </a>
                              )
                            ) : null}

                            {address && (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  !!address
                                    ? (e) => {
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                              >
                                Download
                              </p>
                            )}
                          </div>
                        );
                      })
                      .slice(4, 6)}

                    {dummyImages
                      ?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                style={{
                                  opacity: !!address ? "1" : "0.1",
                                }}
                                loading="lazy"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = scriptURI[i])}
                              />
                            </div>
                            {show !== true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setShow(true);
                                }}
                                className={`twitter-share-button ${styles.shareLink}`}
                                style={{
                                  display: !!address ? "none" : "block",
                                  background: "#3498db",
                                }}
                                target="_blank"
                                href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                              >
                                Tweet
                              </a>
                            ) : null}
                            {show === true ? (
                              discord ? (
                                <div>
                                  <MyConnectWallet
                                    style={{
                                      background: "#7934d9",
                                      border: "none",
                                      display: !!address ? "none" : "block",
                                      width: "140px",
                                    }}
                                    className={`twitter-share-button ${styles.shareLink}`}
                                    icon={false}
                                    renderSecondaryBtn={false}
                                  />
                                </div>
                              ) : (
                                <a
                                  rel="noreferrer"
                                  onClick={() => {
                                    setDiscord(true);
                                  }}
                                  style={{
                                    background: "#7934d9",
                                    display: !!address ? "none" : "block",
                                  }}
                                  target="_blank"
                                  className={`twitter-share-button ${styles.shareLink}`}
                                  href={`https://discord.gg/RXh9sTKake`}
                                >
                                  Discord
                                </a>
                              )
                            ) : null}

                            {address && (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  !!address
                                    ? (e) => {
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                              >
                                Download
                              </p>
                            )}
                          </div>
                        );
                      })
                      .slice(6, 7)}

                    {dummyImages
                      ?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                style={{
                                  opacity: !!address ? "1" : "0.1",
                                }}
                                loading="lazy"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = dummyImages[i])}
                              />
                            </div>
                            {show !== true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setShow(true);
                                }}
                                className={`twitter-share-button ${styles.shareLink}`}
                                style={{
                                  display: !!address ? "none" : "block",
                                  background: "#3498db",
                                }}
                                target="_blank"
                                href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                              >
                                Tweet
                              </a>
                            ) : null}
                            {show === true ? (
                              discord ? (
                                <div>
                                  <MyConnectWallet
                                    style={{
                                      background: "#7934d9",
                                      border: "none",
                                      display: !!address ? "none" : "block",
                                      width: "140px",
                                    }}
                                    className={`twitter-share-button ${styles.shareLink}`}
                                    icon={false}
                                    renderSecondaryBtn={false}
                                  />
                                </div>
                              ) : (
                                <a
                                  rel="noreferrer"
                                  onClick={() => {
                                    setDiscord(true);
                                  }}
                                  style={{
                                    background: "#7934d9",
                                    display: !!address ? "none" : "block",
                                  }}
                                  target="_blank"
                                  className={`twitter-share-button ${styles.shareLink}`}
                                  href={`https://discord.gg/RXh9sTKake`}
                                >
                                  Discord
                                </a>
                              )
                            ) : null}

                            {address && (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  !!address
                                    ? (e) => {
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                              >
                                Download
                              </p>
                            )}
                          </div>
                        );
                      })
                      .slice(7, 8)}
                  </div>
                </div>
              </Slider>

              <div className="d-flex px-2 pb-2 justify-content-between">
                <div className="pointer" onClick={previousSlide}>
                  <LeftArrowIcon />
                </div>
                <div className="pointer" onClick={nextSlide}>
                  <RightArrowIcon />
                </div>
              </div>
            </div>
          }
        </div>

        <div
          style={{
            marginTop: "20px",
          }}
        >
          {publicSale ? (
            isConnected ? (
              // The magic blocker for the minting process -  address === inputValue
              <button
                className="btn"
                style={{
                  color: "#fff",
                  backgroundColor: "#aa0b22",
                  cursor: "pointer",
                }}
                onClick={() => {
                  openMintModal();
                }}
              >
                Mint XTRA Jackets
              </button>
            ) : (
              <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
                }) => {
                  // Note: If your app doesn't use authentication, you
                  // can remove all 'authenticationStatus' checks
                  const ready = mounted && authenticationStatus !== "loading";
                  const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                      authenticationStatus === "authenticated");

                  return (
                    <div
                      {...(!ready && {
                        "aria-hidden": true,
                        style: {
                          opacity: 0,
                          pointerEvents: "none",
                          userSelect: "none",
                        },
                      })}
                    >
                      {(() => {
                        if (!connected) {
                          return (
                            <button
                              onClick={openConnectModal}
                              type="button"
                              className="btn "
                              style={{
                                color: "#fff",
                                backgroundColor: "#aa0b22",
                                cursor: "pointer",
                              }}
                            >
                              Connect Wallet to Mint
                            </button>
                          );
                        }
                      })()}
                    </div>
                  );
                }}
              </ConnectButton.Custom>
            )
          ) : (
            <Link
              to="https://opensea.io/collection/xtra-fantasy-pyramid"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="btn"
                style={{
                  color: "#fff",
                  backgroundColor: "#aa0b22",
                }}
              >
                Get XTRA Fantasy Pyramid
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmptyAuthorizedNFTModal;
