import React from "react";
import { BiWallet } from "react-icons/bi";
import { ConnectButton as RainbowButton } from "@rainbow-me/rainbowkit";
import useMediaQuery from "../../hooks/useMediaQuery";

const ConnectButton = ({
  title = "Connect Wallet",
  className,
  style,
  icon = true,
  renderSecondaryBtn = true,
  customIcon,
  connectedClassName,
}) => {
  const { isSmallerScreen } = useMediaQuery();
  return (
    <RainbowButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        return (
          <div
            {...(!mounted && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!mounted || !account || !chain) {
                return (
                  <button
                    onClick={openConnectModal}
                    type="button"
                    style={style}
                    className={`${className ? className : "btn login-btn"}`}
                  >
                    {title} {icon ? <BiWallet size={16} /> : customIcon}
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    type="button"
                    className="btn login-btn"
                  >
                    Wrong network
                  </button>
                );
              }

              return (
                renderSecondaryBtn && (
                  <div
                    style={{
                      display: "flex",
                      gap: 12,
                      justifyContent: "center",
                    }}
                  >
                    {/* {!isMobile && (
                      <button
                        onClick={openChainModal}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        type="button"
                        className="btn login-btn"
                      >
                        {chain.hasIcon && (
                          <div
                            style={{
                              background: chain.iconBackground,
                              width: 12,
                              height: 12,
                              borderRadius: 999,
                              overflow: "hidden",
                              marginRight: 4,
                            }}
                          >
                            {chain.iconUrl && (
                              <img
                                alt={chain.name ?? "Chain icon"}
                                src={chain.iconUrl}
                              />
                            )}
                          </div>
                        )}

                        {chain.name?.slice(0, 3)}
                      </button>
                    )} */}

                    <button
                      onClick={openAccountModal}
                      type="button"
                      className={`${
                        connectedClassName
                          ? connectedClassName
                          : "btn login-btn m-0"
                      }`}
                      style={{ margin: "0px !important" }}
                    >
                      {`${account.displayName} ${
                        account.displayBalance && !isSmallerScreen
                          ? ` (${account.displayBalance})`
                          : ""
                      }`}
                    </button>
                  </div>
                )
              );
            })()}
          </div>
        );
      }}
    </RainbowButton.Custom>
  );
};

export default ConnectButton;
