import React, { useState, useEffect, useContext } from "react";
import styles from "./style.module.css";
import { BiSearchAlt2 } from "react-icons/bi";
import { MdErrorOutline } from "react-icons/md";
import { ethers } from "ethers";
import newHeroImg from "../../assets/img/core-img/Make-your-NFTs-extraordinary.png";
import UserNFTModal from "../modals/UsersNFTModals/UserNFTModal";
import Modal from "react-modal";
import { ContextApi } from "../../context/ContextApi";
import useMediaQuery from "../../hooks/useMediaQuery";

Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

// const acceptedNFTs = [
//   "0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b",
//   "0xe785e82358879f061bc3dcac6f0444462d4b5330",
//   "0xf61f24c2d93bf2de187546b14425bf631f28d6dc",
//   "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
//   "0x23581767a106ae21c074b2276d25e5c3e136a68b",
//   "0xed5af388653567af2f388e6224dc7c4b3241c544",
//   "0x8a90cab2b38dba80c64b7734e58ee1db38b8992e",
//   "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb",
//   "0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7",
//   "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
//   "0xbd3531da5cf5857e7cfaa92426877b022e612cf8",
//   "0x1a92f7381b9f03921564a437210bb9396471050c",
//   "0xa3aee8bce55beea1951ef834b99f3ac60d1abeeb",
//   "0x5cc5b05a8a13e3fbdb0bb9fccd98d38e50f90c38",
//   "0x1cf159f2c622f8286f5bdcd5e7f91f5b8eec372a",
//   "0xbf530c816ae330e0dd086de738140b637376d1be",
//"0x6300FeEDb0F6C36F988980fe4130eC0f0B43C60A"
// ];

function HeroComponent() {
  // Destructure values coming from context
  const { searchedAddress, setSearchedAddress } = useContext(ContextApi);
  // const msg = useContext(ContextApi);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalMobileIsOpen, setMobileIsOpen] = useState(false);
  const [modal2IsOpen, setIsOpen2] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [authorizedNfts, setAuthorizedNfts] = useState([]);
  const [err, setErr] = useState(false);

  // The setup for the loader
  const [loading, setLoading] = useState(false);

  const { isMobile } = useMediaQuery();

  useEffect(() => {
    if (modalIsOpen || modalMobileIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalIsOpen, modalMobileIsOpen]);

  function openModal() {
    // setIsOpen(true);

    if (isMobile) {
      setMobileIsOpen(true);
    } else {
      setIsOpen(true);
    }
  }

  function closeModal() {
    setMobileIsOpen(false);
    setIsOpen(false);
  }

  function openModal2() {
    setIsOpen2(true);
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  const something = (event) => {
    if (event.keyCode === 13) {
      handleClick(event);
    }
  };

  const getNftAssets = async () => {
    setLoading(true);

    let addr;
    // QuickNode API consumption.
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://dimensional-purple-film.quiknode.pro/ffd6dd8dd1c70b0cf764b0b1635b35472eb1d7cb/"
      );
      // provider.connection.headers = { "x-qn-api-version": 1 };
      if (inputValue.slice(-4) === ".eth") {
        const provider = new ethers.providers.JsonRpcProvider(
          "https://dimensional-purple-film.quiknode.pro/ffd6dd8dd1c70b0cf764b0b1635b35472eb1d7cb/"
        );
        var address = await provider.resolveName(inputValue);

        setInputValue(address);
        setSearchedAddress(address);

        addr = address;
        localStorage.setItem(address, `${JSON.stringify(address)}`);
      } else {
        addr = inputValue;
        localStorage.setItem(addr, `${JSON.stringify(addr)}`);
        setSearchedAddress(addr);
      }
      // console.log("searched guy", searchedAddress);

      const heads = await provider.send("qn_fetchNFTs", {
        wallet: addr,
        omitFields: ["provenance", "traits"],
        page: 1,
        perPage: 40,
        contracts: [
          "0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b",
          "0xe785e82358879f061bc3dcac6f0444462d4b5330",
          "0xf61f24c2d93bf2de187546b14425bf631f28d6dc",
          "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
          "0x23581767a106ae21c074b2276d25e5c3e136a68b",
          "0xed5af388653567af2f388e6224dc7c4b3241c544", // azuki
          "0x8a90cab2b38dba80c64b7734e58ee1db38b8992e",
          "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb",
          "0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7",
          "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
          "0xbd3531da5cf5857e7cfaa92426877b022e612cf8",
          "0x1a92f7381b9f03921564a437210bb9396471050c",
          "0xa3aee8bce55beea1951ef834b99f3ac60d1abeeb",
          "0x5cc5b05a8a13e3fbdb0bb9fccd98d38e50f90c38",
          "0x1cf159f2c622f8286f5bdcd5e7f91f5b8eec372a",
          "0xbf530c816ae330e0dd086de738140b637376d1be",
          "0x6300FeEDb0F6C36F988980fe4130eC0f0B43C60A",
        ],
      });
      setAuthorizedNfts(heads.assets);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setErr(true);
    }
  };

  const handleClick = async (e) => {
    setErr(false);
    getNftAssets();

    e.preventDefault();
    if (inputValue?.slice(-4) === ".eth") {
      openModal();
    } else if (inputValue?.length === 42 && authorizedNfts !== []) {
      openModal();
    } else {
      openModal2();
    }
  };

  useEffect(() => {}, [inputValue]);

  useEffect(() => {}, [searchedAddress]);

  return (
    <section className={styles.hero__section}>
      {/* Everything about the Hero Section UI */}
      <div className="row col-log-12 mb-2">
        {/* The content */}
        <div className="col-md-6 col-xs-12">
          <div className={styles.hero__wrapper}>
            <h1 className={styles.hero__header}>
              Make your NFTs look XTRAordinary
            </h1>
            <p className={styles.hero__paragraph}>
              XTRAordinary fashion items for your NFT avatars. See what’s in
              store today
            </p>
          </div>

          <div className={styles.hero__search}>
            <input
              type="text"
              name="address"
              placeholder="Enter ENS name or Ethereum Address"
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              value={inputValue}
              onKeyDown={(e) => something(e)}
            />

            <button className={styles.search} onClick={handleClick}>
              <BiSearchAlt2 size={16} />
              Search
            </button>
          </div>
        </div>

        {/* The Hero Image */}
        <div className={`col-md-6 col-xs-12 ${styles.miles}`}>
          <img src={newHeroImg} alt="Hero_img" className={styles.heroImage} />
        </div>
      </div>

      {/* User modal when input is valid. */}
      <UserNFTModal
        handleUserModal={openModal}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        authorizedNfts={authorizedNfts}
        loading={loading}
        inputValue={inputValue}
        searchedAddress={searchedAddress}
        err={err}
        setErr={setErr}
        modalMobileIsOpen={modalMobileIsOpen}
      />

      {/* Error Modal */}
      <Modal
        isOpen={modal2IsOpen}
        onRequestClose={closeModal2}
        style={customStyles}
      >
        <div
          style={{
            display: "flex",
            alignItems: "cwenter",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ color: "red" }}>Invalid input passed</h4>

          <button onClick={closeModal2}>
            <MdErrorOutline size={16} />
            <span>Retry</span>
          </button>
        </div>
      </Modal>
    </section>
  );
}

export default HeroComponent;
