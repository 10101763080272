import data from "../../data/static.json";
import logo from "../../assets/img/core-img/logo.png";
import { NavLink, Link } from "react-router-dom";

const ContactInfo = ({ Class, ClassBx, children, title }) => (
  <div className={Class}>
    <div className="contact_info_area d-sm-flex justify-content-between">
      <div className={ClassBx}>
        <h5>{title}</h5>
        {children}
      </div>
    </div>
  </div>
);

const Footer = () => {
  return (
    <footer
      className="footer-area bg-img"
      style={{ backgroundImage: "url(img/core-img/pattern.png)" }}
    >
      <div className="footer-content-area demo">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-lg-4 col-md-6">
              <div className="footer-copywrite-info">
                <div className="copywrite_text fadeInUp" data-wow-delay="0.2s">
                  <div className="footer-logo">
                    <a href="/">
                      <img src={logo} alt="logo" />
                    </a>
                  </div>
                  {/* <p>{data[0]?.footer?.text}</p> */}
                </div>
                {/* Social Icon */}
                <div
                  className="footer-social-info fadeInUp"
                  data-wow-delay="0.4s"
                >
                  {data[0]?.footer?.ListIcon?.map((item, key) => (
                    <Link
                      to={item.link}
                      aria-label={item.ico}
                      key={key}
                      target="_blank"
                    >
                      <i className={item.ico} aria-hidden="true" />
                    </Link>
                  ))}

                  <p> {data[0]?.footer?.text} </p>
                </div>
              </div>
            </div>

            <ContactInfo
              Class="col-12 col-lg-2 col-md-6"
              ClassBx="contact_info mt-s text-center"
              title="SUPPORT"
            >
              {data[0]?.footer?.SUPPORT?.map((item, index) => (
                <NavLink to={item?.link} target="_blank" key={index}>
                  <p>{item.text}</p>
                </NavLink>
              ))}
            </ContactInfo>

            <ContactInfo
              Class="col-12 col-lg-3 col-md-6 "
              ClassBx="contact_info mt-s text-center"
              title="CONTACT US"
            >
              {data[0]?.footer?.CONTACT?.map((item, key) => (
                <Link to={item?.link} target="_blank" key={key}>
                  <p>{item.text}</p>
                </Link>
              ))}
            </ContactInfo>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
