import FAQContainer from "../containers/FAQ";
import Layout from "../layouts";

const FAQ = () => {
  return (
    <Layout Title="FAQ">
      <FAQContainer />
    </Layout>
  );
};

export default FAQ;
