import { CONTRACT_ABI, CONTRACT_ADDRESS } from "../utils";

export const mintMobileStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    borderRadius: "10px",
    overflowX: "hidden",
    overflowY: "auto",
    minWidth: "90vw",
    margin: "15px 0",
    maxHeight: "75vh",
    backgroundColor: "#fff",
    outline: "none",
    padding: "0px 15px 10px",
    zIndex: "100 !important",
  },
};

export const mintDesktopStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    borderRadius: "10px",
    overflow: "hidden",
    backgroundColor: "#fff",
    outline: "none",
    overflowX: "hidden",
    width: "850px",
    margin: "55px 0 0",
    // height: "50vh !important",
    overflowY: "auto",
  },
};

export const whitelistAddresses = [
  "0x7458326A20743D8da065561017c9f2672428210f",
  "0xf18be8A5FcBD320fDe04843954c1c1A155b9Ae2b",
  "0x14254ce9aB9B3aaD797aF7e55374fE97dC981e8C",
  "0x9268d03EfF4A9A595ef619764AFCB9976c0375df",
  "0x63d27819DEe6B4102C0fED91374CfDb31dC5d942",
  "0x99237BaFB5471f446C4623DE4ab9cD25F52fC922",
  "0x2195Dc90fF5c042FaeC70c8Cc18a2737f0348975",
];

export const MINT_KEY =
  "0xdfd8e1d04a057f99207c8b4f44701b969e281ed4dc1c127d757c613bcf60b657";

export const contractConfig = {
  address: CONTRACT_ADDRESS,
  abi: CONTRACT_ABI,
};
