import React from "react";

const CrossIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.14062 15.3087L15.3195 1.12988"
        stroke="#170F49"
        stroke-width="2.15113"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.3195 15.3087L1.14062 1.12988"
        stroke="#170F49"
        stroke-width="2.15113"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CrossIcon;
