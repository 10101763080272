import React, { useEffect, useState } from "react";
import Aos from "aos";

import "aos/dist/aos.css";
import "./assets/css/style.css";
// import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css";
import "@rainbow-me/rainbowkit/styles.css";

// import use context
import { ContextApi } from "./context/ContextApi";
import Routes from "./routes";

// import "bootstrap/dist/js/bootstrap.bundle.min";

const App = () => {
  const [searchedAddress, setSearchedAddress] = useState("hello");

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    document.body.classList.add("darker");
  }, []);

  return (
    <div className="App">
      <ContextApi.Provider value={{ searchedAddress, setSearchedAddress }}>
        <Routes />
      </ContextApi.Provider>
    </div>
  );
};

export default App;
