import React from "react";
import styles from "./style.module.css";
import { BsDot } from "react-icons/bs";

import SwiperCore from "swiper";
import "swiper/css/autoplay";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Autoplay]);
const Sponsors = () => {
  const supportedNFTs = [
    "CloneX",
    "World of Women (WoW)",
    "Mutant Ape Yatch Club",
    "Moonbirds",
    "Azuki",
    "Doodles",
    "CryptoPunks ",
    "Meebits",
    "Bored Ape Yatch Club",
    "Pudgy Penguin",
    "Cool Cats",
    "Vee Friends ",
    "Sandbox",
    "Alpha Card",
    "XTRA for Lions ",
    "World of Women Galaxy (WOWG)",
  ];

  const swiperBreakpoints = {
    350: {
      slidesPerView: 2,
    },
    576: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 4,
    },
    992: {
      slidesPerView: 4,
    },
    1200: {
      slidesPerView: 5,
    },
  };
  return (
    <section className={styles.section__bg}>
      <div className="moving-bg">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={4}
          breakpoints={swiperBreakpoints}
          loop="true"
          speed={10000}
          autoplay={true}
          className="mySwiper"
        >
          {supportedNFTs.slice(0, 7).map((item, idx) => {
            return (
              <SwiperSlide key={idx}>
                <div className="d-flex justify-content-center align-items-center">
                  <BsDot color="#fff" size={24} />
                  <p style={{ marginBottom: "0px" }}>{item}</p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={5}
          breakpoints={swiperBreakpoints}
          loop="true"
          speed={8000}
          autoplay={true}
          className="mySwiper"
        >
          {supportedNFTs.slice(7, 15).map((item, idx) => {
            return (
              <SwiperSlide key={idx}>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ marginTop: "10px" }}
                >
                  <BsDot color="#fff" size={24} />
                  <p style={{ marginBottom: "0px" }}>{item}</p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Sponsors;
