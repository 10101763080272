import React from "react";
import jacketVideo from "../../../assets/img/core-img/About-the-Drop.gif";

import { AiOutlineMenu } from "react-icons/ai";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { useAccount } from "wagmi";
import CrossIcon from "../../../assets/icons/CrossIcon";

// mint mobile component
const MintModalMobile = ({
  closeMintAndGetLastAddress,
  maxMintWarning,
  handleDecrease,
  counter,
  handleIncrease,
  handleMint,
  totalMintPrice,
  maxMintReached,
  isLoading,
  isConnected,
  disableMint,
  searchedAddress,
  openPostModal,
  isMintDisable,
  publicSale,
  mintBtnText,
}) => {
  const { isSmallerScreen, isMobile } = useMediaQuery();
  const { address } = useAccount();
  return (
    <div>
      <div className="mt-4 mb-2">
        <div
          style={{ position: "absolute", right: "5%", top: "2%" }}
          onClick={closeMintAndGetLastAddress}
        >
          <CrossIcon className="pointer" />
        </div>

        <div style={{ width: "100%" }}>
          <p
            style={{
              color: "#000",
              fontWeight: "bold",
              lineHeight: "1.3",
              marginBottom: "8px",
            }}
          >
            Welcome to the randomized mint of XTRA Jackets with 8 different
            rarities
          </p>
        </div>

        <div style={{ width: "100%", margin: "auto" }}>
          <div
            style={{
              display: isSmallerScreen ? "block" : "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <div
              style={{
                background: "rgba(244, 246, 254, 0.8)",
                padding: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: isSmallerScreen ? "100%" : "55%",
                borderRadius: "5px",
              }}
            >
              <img
                draggable="false"
                src={jacketVideo}
                className="center-block img-responsive phone-img"
                alt="about"
              />
            </div>

            <div
              style={{
                width: isSmallerScreen ? "100%" : "43%",
                paddingLeft: "15px",
                paddingRight: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h4
                  style={{
                    color: "#000",
                    lineHeight: "1.2",
                    marginTop: isSmallerScreen ? "12px" : "",
                  }}
                >
                  Perks
                </h4>

                <ul style={{ marginTop: "0rem", marginBottom: "10px" }}>
                  <li className="m-1" style={{ listStyle: "inside" }}>
                    Access 3D files
                  </li>
                  <li className="m-1" style={{ listStyle: "inside" }}>
                    Metaverse Drops
                  </li>
                  <li className="m-1" style={{ listStyle: "inside" }}>
                    Own the IP + Forging Rights
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            style={{
              position: isMobile ? "sticky" : "relative",
              bottom: isMobile ? "-2%" : "",
              zIndex: "20",
              background: "#fff",
              padding: "10px",
            }}
          >
            <div>
              <h6 style={{ color: "red", fontSize: "12px" }}>
                {disableMint()}
              </h6>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <button
                style={{
                  flex: "1",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                  borderRadius: "10px",
                  color: "#979797",
                }}
                onClick={handleDecrease}
              >
                -
              </button>
              <div
                style={{
                  flex: "3",
                  background: "#F2F2F2",
                  marginRight: ".5rem",
                  marginLeft: ".5rem",
                  textAlign: "center",
                  padding: "5px",
                }}
              >
                {counter}
              </div>
              <button
                style={{
                  flex: "1",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  color: "#979797",
                  cursor: maxMintWarning ? "not-allowed" : "pointer",
                }}
                onClick={handleIncrease}
              >
                +
              </button>
            </div>
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontSize: "10px",
                  color: "black",
                  fontWeight: "700",
                }}
              >
                <span style={{ marginRight: "5px" }}>
                  Price: {totalMintPrice} ETH
                </span>
                <AiOutlineMenu size={13} color={"black"} />
              </p>
            </div>
            <button
              className="btn active"
              style={{
                color: "#fff",
                backgroundColor:
                  !publicSale && searchedAddress !== address
                    ? "grey"
                    : "#aa0b22",
                width: "100%",
              }}
              disabled={isMintDisable}
              onClick={() => (maxMintReached ? openPostModal() : handleMint())}
            >
              {mintBtnText()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintModalMobile;
