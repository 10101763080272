import CollectionContainer from "../containers/Collection";
import Layout from "../layouts";

const Collection = () => {
  return (
    <Layout Title="Collection">
      <CollectionContainer />
    </Layout>
  );
};

export default Collection;
