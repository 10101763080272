export const mobilePostModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    borderRadius: "10px",
    overflowX: "hidden",
    overflowY: "scroll !important",
    overflow: "-moz-scrollbars-vertical",
    width: "90vw",
    marginTop: "15px",
    height: "70vh",
    backgroundColor: "#fff",
    outline: "none",
    padding: " 15px",
  },
};

export const desktopPostModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    borderRadius: "10px",
    overflow: "hidden",
    backgroundColor: "#fff",
    outline: "none",
    overflowX: "hidden",
    width: "880px",
    overflowY: "auto",
    margin: "55px 0px 0px",
    maxHeight: "85vh",
  },
};
