import React from "react";

const CheckIcon = () => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3409 1.30225L5.45338 9.18973L1.86816 5.60451"
        stroke="#027A48"
        strokeWidth="2.15113"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
