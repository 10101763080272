import SectionHeading from "../SectionHeading";

const TermsAndConditionArea = ({
  ClassItem = "contact_us_area section-padding-100-0",
  data,
}) => {
  console.log(data);
  return (
    <div
      className={ClassItem}
      style={{
        backgroundImage: "url(img/core-img/pattern.png)",
        marginBottom: "60px",
      }}
    >
      <div className="container">
        <div className="m-lg-0 m-4 text-lg-left text-justify">
          <SectionHeading title={data?.SectionHeading.title} />
          <div className="content">
            <div className="description">
              <p>{data.SectionHeading.paragraph1}</p>
              <p>{data.SectionHeading.paragraph2}</p>
            </div>

            <div className="points">
              {data.points.map((item) => {
                return (
                  <div key={item.pointName}>
                    <h4>{item.pointName}</h4>
                    {item.answer.map((answer, index) => {
                      return <p key={index}>{answer}</p>;
                    })}
                  </div>
                );
              })}
            </div>

            <div className="conclusion">
              {data.conclusion.map((item, index) => {
                return (
                  <div key={index}>
                    <p>{item}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionArea;
