import SectionHeading from "../SectionHeading";
import { Link } from "react-router-dom";
import * as DOMPurify from "dompurify";

const FAQ = ({ data }) => {
  return (
    <div className="faq-timeline-area section-padding-100-85 p-5" id="faq">
      <div className="container">
        <SectionHeading
          ClassTitle="gradient-t"
          title={data.SectionHeading.title}
          otherTitle={data.SectionHeading.otherTitle}
        />
        <div className="row  d-flex align-items-center justify-content-center">
          <div className="service-img-wrapper "></div>
          <div className="">
            <div className="dream-faq-area mt-s ">
              <dl
                style={{
                  marginBottom: 0,
                  whiteSpace: "pre-line",
                  verticalAlign: "bottom",
                }}
              >
                {data?.listQues?.map((item, index) => (
                  <div key={index}>
                    <dt
                      className="wave"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${item.ID}`}
                      aria-expanded="false"
                    >
                      {item.title}
                    </dt>
                    <dd
                      data-aos="fade-up"
                      id={item.ID}
                      aria-labelledby="headingOne"
                      data-bs-parent="#basicAccordion"
                      className="accordion-collapse collapse"
                    >
                      <div className="accordion-body">
                        {item.path ? (
                          item.text2 ? (
                            <p>
                              {item.text}
                              <span>
                                <Link to={item?.path} target="_blank">
                                  {item?.text2}
                                </Link>
                              </span>
                            </p>
                          ) : (
                            <Link to={item.path} target="_blank">
                              {item.text}
                            </Link>
                          )
                        ) : (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(item.text, {
                                USE_PROFILES: { html: true },
                              }),
                            }}
                          ></p>
                        )}
                      </div>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
