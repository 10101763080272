import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";
import DiscoverOurNewCollection from "../../components/DiscoverOurNewCollection";
import HowItWorks from "../../components/HowItWorks";
import FAQ from "../../components/FAQ";
import data from "../../data/static.json";
import WelcomeArea from "./WelcomeArea";
import TeamArea from "../../components/TeamArea";
import AboutImage from "../../assets/img/core-img/About-the-Drop.gif";
import Sponsors from "../../components/Sponsors";
import SectionHeading from "../../components/SectionHeading";
import styles from "../../assets/css/styles.module.css";

const HomeDemo1Container = () => {
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <>
      <WelcomeArea data={data[0].HomeDemo1.WelcomeArea} />

      <section className="features section-padding-100">
        <div className="container ">
          <div className="row align-items-center">
            <div className="service-img-wrapper col-lg-6 col-md-12 col-sm-12 order-lg-0 order-1">
              <div className="image-box">
                <img
                  draggable="false"
                  src={AboutImage}
                  className="center-block img-responsive phone-img"
                  alt="about"
                />
              </div>
            </div>
            <div className="service-img-wrapper col-lg-6 col-md-9 col-sm-12 mt-s text-lg-left text-center order-lg-1 order-0">
              <div className="m-lg-0 m-5">
                <div className={styles.about__header}>
                  <SectionHeading title={"About The Drop"} />
                </div>

                <div style={{ marginTop: "-60px" }}>
                  <h4 data-aos="fade-up" style={{ color: "#FFFFFF" }}>
                    {data[0].HomeDemo2.title}
                  </h4>
                  <p data-aos="fade-up" style={{ color: "#EAEAEA" }}>
                    {data[0].HomeDemo2.textUp}
                  </p>
                  <p data-aos="fade-up" style={{ color: "#EAEAEA" }}>
                    {data[0].HomeDemo2.textDown}
                  </p>
                  <a
                    className="btn mt-20"
                    style={{ color: "#AA0B22" }}
                    data-aos="fade-up"
                    href="1"
                  >
                    {data[0].HomeDemo2.btnText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Sponsors />
      <HowItWorks
        ClassItem="how-steps section-padding-lg-100 section-padding-100-40"
        data={data[0].HowItWorks}
      />
      <DiscoverOurNewCollection data={data[0].DiscoverOurNewCollection} />

      <TeamArea data={data[0].TeamArea} />

      <FAQ data={data[0].FAQ} />
    </>
  );
};

export default HomeDemo1Container;
