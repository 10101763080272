import _ from "lodash";

export const handelTitle = (title) => {
  document.title = title;
};

export const Addshrink = () => {
  let RelBanner = document.querySelector("#banner");

  RelBanner &&
    window.addEventListener("scroll", (e) => {
      if (window.pageYOffset > 86) {
        RelBanner.classList.add("shrink");
      } else {
        RelBanner.classList.remove("shrink");
      }
    });
};

export const moveSmooth = () => {
  window.scrollTo({
    behavior: "smooth",
  });
};

export const loader = () => {
  let fadeTarget = document.getElementById("preloader");

  function fadeOutEffect() {
    var fadeEffect = setInterval(function () {
      if (fadeTarget.style.opacity > 0) {
        fadeTarget.style.opacity -= 0.1;
      } else {
        clearInterval(fadeEffect);
        fadeTarget.style.display = "none";
      }
    }, 100);
  }

  window.onload = setTimeout(fadeOutEffect, 1000);
};

export const addRemoveClassBody = (NameOfClass) => {
  document.body.className = "";
  document.body.classList.add(NameOfClass);
};

export const shortenAddress = (x) => {
  return x.slice(0, 6) + "..." + x.slice(37, 42);
};

// export const CONTRACT_ADDRESS = "0x348bf3b3ac0724022f37da01eb304491d339efd1"; // SEPOLIA
export const CONTRACT_ADDRESS = "0x969fa35F20923671049829f2d270d229fEEa45C1"; // MAINNET

export const CONTRACT_ABI = require("../data/abi.json");

export const mapKeysToCamelCase = (obj) => {
  return _.mapKeys(obj, (v, k) => _.camelCase(k));
};

export const countUniqueTokens = (arr) => {
  let obj = {};
  arr.forEach((str) => {
    obj[str] = (obj[str] || 0) + 1;
  });
  return Object.entries(obj).map(([value, count]) => ({ value, count }));
};
