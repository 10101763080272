import { useEffect, useState } from "react";
import data from "../../data/static.json";
import logo from "../../assets/img/core-img/logo.png";
import opensea from "../../assets/img/core-img/openseaLogowhite.png";
import discord from "../../assets/img/core-img/discordwhite.png";
import twitter from "../../assets/img/core-img/Twitterwhite.png";
import { Addshrink, moveSmooth } from "../../utils/";
import { RiMenu3Fill, RiCloseFill } from "react-icons/ri";

import "./header.css";

import Preloader from "../../components/Preloader";

import { Link, NavLink } from "react-router-dom";
import ConnectButton from "../../components/ConnectButton";
import useMediaQuery from "../../hooks/useMediaQuery";

const Header = ({ Title }) => {
  const [open, setOpen] = useState(false);

  const { isSmallerScreen, isMobile } = useMediaQuery();

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    Addshrink();
  }, []);

  useEffect(() => {
    moveSmooth();
  }, []);

  return (
    <>
      <Preloader Title={Title} />

      <nav
        className="navbar navbar-expand-lg p-lg-3 py-1 px-4 navbar-white fixed-top"
        id="banner"
        style={{ background: "#000", color: "#ffff" }}
      >
        <div className="fixed-top grad mb-7">
          <p>Public MINT is Live! Use any wallet to Mint XTRA Jackets</p>
        </div>
        <div className="container mt-3">
          {/* Brand */}
          <a className="navbar-brand cursor-pointer" href="/">
            <span>
              <img
                draggable="false"
                src={logo}
                width={isSmallerScreen ? "75px" : "auto"}
                alt="logo"
              />
            </span>
          </a>

          <div className="d-lg-none mt-2 d-block">
            <ConnectButton />
          </div>
          <div className="d-flex align-items-center gap-2">
            {/* Menu hamburger */}
            {open === true ? (
              <RiCloseFill
                size={30}
                color="white"
                className="navbar-toggle  d-lg-none d-block"
                // type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo01"
                style={{ backgroundColor: "transparent" }}
                onClick={handleClick}
              />
            ) : (
              <RiMenu3Fill
                size={30}
                className="navbar-toggle d-lg-none d-block"
                color="white"
                // type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo01"
                style={{ backgroundColor: "transparent" }}
                onClick={handleClick}
              />
            )}
          </div>
          {/* </button> */}
          {/* Navbar links */}
          <div
            className={`collapse navbar-collapse m-auto w-100 text-center ${
              isMobile === true ? "bg-dark" : null
            }`}
            id="navbarTogglerDemo01"
          >
            <ul className="navbar-nav ml-auto " style={{ color: "#ffff" }}>
              <li className="nav-item  p-lg-0 m-2">
                <a
                  className="nav-link  text-uppercase"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  style={{ color: "#ffff" }}
                >
                  Home
                </a>
              </li>

              {data[0]?.header?.MenuInfo?.map((item, key) => (
                <li className="nav-item  p-lg-0 m-2" key={key}>
                  <NavLink
                    className="nav-link text-uppercase"
                    to={item.path}
                    style={{ color: "#ffff" }}
                    target={`${"_blank"}`}
                  >
                    {item.nameLink}
                  </NavLink>
                </li>
              ))}

              <li className="nav-item">
                <Link
                  className="nav-link d-flex justify-content-center align-center"
                  id="navbarDropdown"
                  aria-expanded="false"
                  to={"https://opensea.io/collection/xtra-ascends"}
                  target="_blank"
                  reloadDocument
                >
                  <div>
                    <img
                      src={opensea}
                      alt="opensea"
                      style={{ width: "35px", height: "35px" }}
                    />
                  </div>
                  {isMobile ? (
                    <span className="pl-2 mt-2" style={{ color: "white" }}>
                      Opensea
                    </span>
                  ) : null}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link d-flex justify-content-center align-center"
                  id="navbarDropdown"
                  aria-expanded="false"
                  to={"https://twitter.com/0xXtra/"}
                  target="_blank"
                  reloadDocument
                >
                  <div>
                    <img
                      style={{ width: "35px", height: "35px" }}
                      src={twitter}
                      alt="twitter"
                    />
                  </div>
                  {/* <FaTwitter size={20} color={"white"} />{" "} */}
                  {isMobile ? (
                    <span className="pl-2 mt-2" style={{ color: "white" }}>
                      Twitter
                    </span>
                  ) : null}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link d-flex justify-content-center align-center"
                  id="navbarDropdown"
                  aria-expanded="false"
                  to={"https://discord.gg/RXh9sTKake"}
                  target="_blank"
                  reloadDocument
                >
                  <div>
                    <img
                      style={{ width: "35px", height: "35px" }}
                      src={discord}
                      alt="discord"
                    />
                  </div>
                  {/* <FaDiscord size={20} color={"white"} />{" "} */}
                  {isMobile ? (
                    <span className="pl-2 mt-2" style={{ color: "white" }}>
                      Discord
                    </span>
                  ) : null}
                </Link>
              </li>
            </ul>
            <div className="ml-4 my-4">
              <ConnectButton />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
