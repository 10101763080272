import { useMutation } from "@tanstack/react-query";
import httpClient from "../services/httpClient";

export const useApproveTransaction = (onSuccess) => {
  return useMutation((data) => {
    const requestData = {
      mintId: data.mintId,
      transactionStatus: data.transactionStatus,
    };
    return httpClient
      .post("/approve-transaction", requestData, {
        headers: {
          Authorization: `Bearer ${data.authToken}`,
        },
      })
      .then((res) => {
        if (onSuccess) {
          onSuccess(res.data.data);
        }
        return res.data.data;
      });
  });
};
