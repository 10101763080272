import React from "react";

const LeftArrowIcon = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3635 3.44009C9.96875 3.44009 3.16348 10.2454 3.16348 18.6401C3.16348 27.0348 9.96875 33.8401 18.3635 33.8401C26.7582 33.8401 33.5635 27.0348 33.5635 18.6401C33.5635 10.2454 26.7582 3.44009 18.3635 3.44009Z"
        stroke="#979797"
        strokeOpacity="0.5"
        strokeWidth="2.25039"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3623 12.5597L12.2823 18.6397L18.3623 24.7197"
        stroke="#979797"
        strokeOpacity="0.5"
        strokeWidth="2.25039"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.4434 18.6399H12.2834"
        stroke="#979797"
        strokeOpacity="0.5"
        strokeWidth="2.25039"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeftArrowIcon;
