import { useMediaQuery as useRRMediaQuery } from "react-responsive";

const useMediaQuery = () => {
  const isDesktop = useRRMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isMobile = useRRMediaQuery({
    query: "(max-width: 992px)",
  });

  const isSmallerScreen = useRRMediaQuery({
    query: "(max-width: 572px)",
  });
  return { isDesktop, isMobile, isSmallerScreen };
};

export default useMediaQuery;
