import React, { useState } from "react";

import LeftArrowIcon from "../../../assets/icons/LeftArrowIcon";
import RightArrowIcon from "../../../assets/icons/RightArrowIcon";
import { saveAs } from "file-saver";
import MyConnectWallet from "../../ConnectButton";
import {
  BsArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { AiOutlineWarning } from "react-icons/ai";
import CheckIcon from "../../../assets/icons/CheckIcon";
import CrossIcon from "../../../assets/icons/CrossIcon";

import styles from "./style.module.css";
import "./slider2.css";
import Slider from "react-slick";
import { toast } from "react-toastify";

const AuthorizedNFTModal = ({
  closeModal,
  address,
  show,
  setShow,
  discord,
  setDiscord,
  scriptURI,
  isMobile,
  scrollLEFT,
  authorizedNfts,
  btnRef,
  formatUrl,
  setErr,
  setProcess,
  setScriptURI,
  newData,
  handleImageDownload,
  err,
  loading,
  isConnected,
  searchedAddress,
  inputValue,
  warning,
  handleSelected,
  scrollRIGHT,
  setSelected,
  openTheModal,
  xfl,
  publicSale,
}) => {
  const [slider, setSlider] = useState(null);
  const nextSlide = () => {
    slider.slickNext();
  };

  const previousSlide = () => {
    slider.slickPrev();
  };
  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    lazyLoad: true,
  };

  return isMobile ? (
    <>
      <div
        style={{
          position: "sticky",
          top: "-3%",
          zIndex: 10,
          backgroundColor: "#fff",
        }}
        className="p-4"
      >
        <div onClick={closeModal}>
          <CrossIcon className="pointer position-absolute top-0 end-0" />
        </div>
        <div className="d-flex position-sticky justify-content-between align-center bg-white ">
          <p className="text-black mb-0" style={{ color: "black" }}>
            Select your NFT Avatar
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "auto",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
          id="authorized-scroll"
        >
          <BsFillArrowLeftCircleFill
            onClick={scrollLEFT}
            size={30}
            color="black"
            style={{
              position: "absolute",
              top: "50%",
              marginLeft: "0px",
              transform: "translate(-50%, -50%)",
              width: "30px",
              height: "20px",
              cursor: "pointer",
            }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            {authorizedNfts.map((item, index) => {
              return (
                item && (
                  <div
                    style={{
                      margin: "5px",
                      display: "inline-block",
                      height: "40%",
                    }}
                    key={index}
                  >
                    {/* Prevent showing unknown for null imageUrl */}
                    {item.imageUrl === null ||
                    item.imageUrl === undefined ? null : (
                      <img
                        ref={btnRef}
                        loading="lazy"
                        src={
                          item.imageUrl?.slice(-3) !== "mp4"
                            ? item.description === "Azuki"
                              ? formatUrl(item?.imageUrl)
                              : item?.imageUrl
                            : xfl
                        }
                        onError={(e) => (e.target.src = authorizedNfts[0])}
                        alt={item?.description}
                        style={{
                          width: "50px",
                          flex: "0 0 25%",
                          margin: "20px",
                          cursor: "pointer",
                          borderRadius: "9px",

                          height: "50px",
                        }}
                        onClick={(e) => {
                          setErr(false);
                          setProcess(true);
                          setScriptURI([]);
                          // console.log(e.target.currentSrc);
                          setSelected(e.target.currentSrc);

                          newData = {
                            image_url: item?.imageUrl,
                            collection_name: item?.collectionName,
                            name: item?.name,
                            collection_token_id: item?.collectionTokenId,
                          };

                          handleImageDownload(newData);
                        }}
                      />
                    )}
                  </div>
                )
              );
            })}
          </div>

          <BsArrowRightCircleFill
            onClick={scrollRIGHT}
            style={{
              position: "absolute",
              top: "50%",
              left: "100%",
              marginLeft: "0px",
              transform: "translate(-50%, -50%)",
              width: "30px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>

      <div
        style={{
          background: "rgba(244, 246, 254, 0.8)",
          display: "flex",
          alignItems: "center",
          padding: "7px 10px",
          justifyContent: "space-between",
        }}
      >
        {scriptURI.length === 0 ? (
          err === true ? (
            <div
              style={{
                height: "30vh",

                margin: "auto",
                width: "100%",
              }}
            >
              <p style={{ color: "#000" }}>Network failed. Please retry...</p>
            </div>
          ) : (
            <LoadingOverlay
              active={loading}
              spinner={<ScaleLoader color="#fff" />}
            >
              <div
                style={{
                  height: "300px",
                  width: "300px",
                  transform: "translate(50%, 50%)",
                }}
              >
                <ScaleLoader color="#aa0b22" />
              </div>
            </LoadingOverlay>
          )
        ) : (
          <div className="d-lg-none d-block">
            <div className="mobile_res_modal d-lg-none d-block">
              <div className="" style={{ height: "25vh" }}>
                <div className="row my-3">
                  {scriptURI
                    .map((item, i) => {
                      return (
                        <div className="col-6 text-center" key={i}>
                          <img src={item} alt="item" />

                          <p
                            style={{
                              color: "#027A48",
                              cursor: "pointer",
                              marginBottom: "0px",
                            }}
                            onClick={(e) => {
                              saveAs(item, `Xtra${i + 1}`);
                              toast.success(`Downloading Xtra${i + 1}...`);
                            }}
                          >
                            Download
                          </p>
                        </div>
                      );
                    })
                    .slice(0, 2)}
                </div>

                <div className="row my-3">
                  {scriptURI
                    .map((item, i) => {
                      return (
                        <div className="col-6 text-center" key={i}>
                          <img
                            src={item}
                            alt="item"
                            style={{
                              opacity: show || !!address ? "1" : "0.1",
                              // objectFit: "contain",
                            }}
                          />

                          {show === true || !!address ? (
                            <p
                              style={{
                                color: "#027A48",
                                cursor: "pointer",
                                display: "block",
                                marginBottom: "0px",
                              }}
                              onClick={
                                show || !!address
                                  ? (e) => {
                                      // console.log(item);
                                      saveAs(item, `Xtra${i + 1}`);
                                      toast.success(
                                        `Downloading Xtra${i + 1}...`
                                      );
                                    }
                                  : null
                              }
                            >
                              Download
                            </p>
                          ) : null}

                          {show !== true ? (
                            <a
                              rel="noreferrer"
                              onClick={() => {
                                setShow(true);
                              }}
                              style={{
                                background: "#3498db",

                                borderRadius: "20px",
                                fontFamily: "Arial",
                                color: "#ffffff",
                                fontSize: "14px",
                                padding: "10px 16px",
                                textDecoration: "none",
                                position: "absolute",
                                transform: "translate(-50%, -50%)",
                                left: "50%",
                                top: "50%",
                                display: !!address ? "none" : "block",
                              }}
                              target="_blank"
                              className={`twitter-share-button ${styles.shareLink}`}
                              href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                            >
                              Tweet
                            </a>
                          ) : null}
                        </div>
                      );
                    })
                    .slice(2, 3)}

                  {scriptURI
                    .map((item, i) => {
                      return (
                        <div className="col-6 text-center" key={i}>
                          <img
                            src={`${item}`}
                            alt="full"
                            loading="lazy"
                            className="lazyload"
                            onError={(e) => (e.target.src = scriptURI[i])}
                            style={{
                              opacity: discord || !!address ? "1" : "0.1",
                            }}
                          />
                          {show !== true ? (
                            <a
                              rel="noreferrer"
                              onClick={() => {
                                setShow(true);
                              }}
                              style={{
                                background: "#3498db",
                                display: !!address ? "none" : "block",
                              }}
                              target="_blank"
                              className={`twitter-share-button ${styles.shareLink}`}
                              href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                            >
                              Tweet
                            </a>
                          ) : null}
                          {show === true ? (
                            <a
                              rel="noreferrer"
                              onClick={() => {
                                setDiscord(true);
                              }}
                              style={{
                                background: "#7934d9",
                                display: discord ? "none" : "block",
                              }}
                              target="_blank"
                              className={`twitter-share-button ${styles.shareLink}`}
                              href={`https://discord.gg/RXh9sTKake`}
                            >
                              Discord
                            </a>
                          ) : null}
                          {discord === true || !!address ? (
                            <p
                              style={{
                                color: "#027A48",
                                cursor: "pointer",
                                display: "block",
                                marginBottom: "0px",
                              }}
                              onClick={
                                discord || !!address
                                  ? (e) => {
                                      // console.log(item);
                                      saveAs(item, `Xtra${i + 1}`);
                                      toast.success(
                                        `Downloading Xtra${i + 1}...`
                                      );
                                    }
                                  : null
                              }
                            >
                              Download
                            </p>
                          ) : null}
                        </div>
                      );
                    })
                    .slice(3, 4)}
                </div>

                <div className="row my-3">
                  {scriptURI
                    .map((item, i) => {
                      return (
                        <div className="col-6 text-center" key={i}>
                          <img
                            src={`${item}`}
                            alt="full"
                            loading="lazy"
                            className="lazyload"
                            onError={(e) => (e.target.src = scriptURI[i])}
                            style={{
                              opacity: !!address ? "1" : "0.1",
                              // objectFit: "contain",
                            }}
                          />

                          {show !== true ? (
                            <a
                              rel="noreferrer"
                              onClick={() => {
                                setShow(true);
                              }}
                              style={{
                                background: "#3498db",
                                backgroundImage:
                                  "-webkit-linear-gradient(top, #3498db, #2980b9)",
                                webkitBorderRadius: "28",
                                mozAdius: "28",
                                borderRadius: "20px",
                                fontFamily: "Arial",
                                color: "#ffffff",
                                fontSize: "14px",
                                padding: "10px 16px",
                                textDecoration: "none",
                                position: "absolute",
                                transform: "translate(-50%, -50%)",
                                left: "50%",
                                top: "50%",
                                display: !!address ? "none" : "block",
                                // transform: "translateY(120%)",
                              }}
                              target="_blank"
                              className={`twitter-share-button ${styles.shareLink}`}
                              href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                            >
                              Tweet
                            </a>
                          ) : null}

                          {show === true ? (
                            discord ? (
                              <div>
                                <MyConnectWallet
                                  style={{
                                    background: "#7934d9",
                                    border: "none",
                                    display: !!address ? "none" : "block",
                                  }}
                                  className={`twitter-share-button ${styles.shareLink}`}
                                  icon={false}
                                  renderSecondaryBtn={false}
                                />
                              </div>
                            ) : (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setDiscord(true);
                                }}
                                style={{
                                  background: "#7934d9",

                                  borderRadius: "20px",
                                  fontFamily: "Arial",
                                  color: "#ffffff",
                                  fontSize: "14px",
                                  padding: "10px 16px",
                                  textDecoration: "none",
                                  position: "absolute",
                                  transform: "translate(-50%, -50%)",
                                  left: "50%",
                                  top: "50%",
                                  display: !!address ? "none" : "block",
                                  // transform: "translateY(120%)",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://discord.gg/RXh9sTKake`}
                              >
                                Discord
                              </a>
                            )
                          ) : null}
                          {!!address ? (
                            <div>
                              <a
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  !!address
                                    ? (e) => {
                                        // console.log(item);
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                                href={item}
                                download
                                target={"_self"}
                              >
                                Download
                              </a>
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                    .slice(4, 6)}
                </div>

                <div className="row my-3">
                  {scriptURI
                    .map((item, i) => {
                      return (
                        <div className="col-6 text-center" key={i}>
                          <img
                            src={`${item}`}
                            alt="full"
                            loading="lazy"
                            className="lazyload"
                            onError={(e) => (e.target.src = scriptURI[i])}
                            style={{
                              opacity: !!address ? "1" : "0.1",
                              // objectFit: "contain",
                            }}
                          />

                          {show !== true ? (
                            <a
                              rel="noreferrer"
                              onClick={() => {
                                setShow(true);
                              }}
                              style={{
                                background: "#3498db",
                                webkitBorderRadius: "28",
                                mozAdius: "28",
                                borderRadius: "20px",
                                fontFamily: "Arial",
                                color: "#ffffff",
                                fontSize: "14px",
                                padding: "10px 16px",
                                textDecoration: "none",
                                position: "absolute",
                                transform: "translate(-50%, -50%)",
                                left: "50%",
                                top: "50%",
                                display: !!address ? "none" : "block",
                              }}
                              target="_blank"
                              className={`twitter-share-button ${styles.shareLink}`}
                              href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                            >
                              Tweet
                            </a>
                          ) : null}

                          {show === true ? (
                            discord ? (
                              <div>
                                <MyConnectWallet
                                  style={{
                                    background: "#7934d9",
                                    border: "none",
                                    display: !!address ? "none" : "block",
                                  }}
                                  className={`twitter-share-button ${styles.shareLink}`}
                                  icon={false}
                                  renderSecondaryBtn={false}
                                />
                              </div>
                            ) : (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setDiscord(true);
                                }}
                                style={{
                                  background: "#7934d9",

                                  borderRadius: "20px",
                                  fontFamily: "Arial",
                                  color: "#ffffff",
                                  fontSize: "14px",
                                  padding: "10px 16px",
                                  textDecoration: "none",
                                  position: "absolute",
                                  transform: "translate(-50%, -50%)",
                                  left: "50%",
                                  top: "50%",
                                  display: !!address ? "none" : "block",
                                  // transform: "translateY(120%)",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://discord.gg/RXh9sTKake`}
                              >
                                Discord
                              </a>
                            )
                          ) : null}
                          {!!address ? (
                            <div>
                              <a
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  !!address
                                    ? (e) => {
                                        // console.log(item);
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                                href={item}
                                download
                                target={"_self"}
                              >
                                Download
                              </a>
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                    .slice(6, 8)}
                </div>

                <div
                  style={{
                    marginTop: "20px",
                    padding: "10px 5px",
                    position: "sticky",
                    bottom: "-3%",
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                  className="text-center"
                >
                  {isConnected ? (
                    // The magic blocker for the minting process -  address === inputValue
                    <button
                      className="btn"
                      style={{
                        color: "#fff",
                        backgroundColor:
                          publicSale || address === inputValue
                            ? "#aa0b22"
                            : "grey",
                        cursor:
                          publicSale || address === inputValue
                            ? "pointer"
                            : "not-allowed",
                      }}
                      onClick={
                        publicSale || address === searchedAddress
                          ? openTheModal
                          : null
                      }
                      disabled={!publicSale && address !== searchedAddress}
                    >
                      Mint XTRA Jackets
                    </button>
                  ) : (
                    <div>
                      <ConnectButton.Custom>
                        {({
                          account,
                          chain,
                          openConnectModal,
                          authenticationStatus,
                          mounted,
                        }) => {
                          // Note: If your app doesn't use authentication, you
                          // can remove all 'authenticationStatus' checks
                          const ready =
                            mounted && authenticationStatus !== "loading";
                          const connected =
                            ready &&
                            account &&
                            chain &&
                            (!authenticationStatus ||
                              authenticationStatus === "authenticated");

                          return (
                            <div
                              {...(!ready && {
                                "aria-hidden": true,
                                style: {
                                  opacity: 0,
                                  pointerEvents: "none",
                                  userSelect: "none",
                                },
                              })}
                            >
                              {(() => {
                                if (!connected) {
                                  return (
                                    <button
                                      onClick={openConnectModal}
                                      type="button"
                                      className="btn"
                                      style={{
                                        color: "#fff",
                                        backgroundColor:
                                          scriptURI.length === 0
                                            ? "grey"
                                            : "#aa0b22",
                                        cursor:
                                          scriptURI.length === 0
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      Connect Wallet to Mint
                                    </button>
                                  );
                                }
                              })()}
                            </div>
                          );
                        }}
                      </ConnectButton.Custom>
                    </div>
                  )}

                  {!isConnected && !publicSale && (
                    <div
                      style={{
                        display: "flex",
                        margin: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                        marginTop: "10px",
                      }}
                    >
                      <CheckIcon />
                      <p style={{ color: "#000", marginBottom: "0px" }}>
                        Connect wallet to verify ownership and unlock rare
                        Jacket Designs
                      </p>
                    </div>
                  )}

                  {!!address && !publicSale ? (
                    searchedAddress !== address ? (
                      <div
                        style={{
                          display: "flex",
                          margin: "auto",
                          justifyContent: "center",
                        }}
                      >
                        <AiOutlineWarning size={20} color="red" />
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          Connected wallet is not same as input address.
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {warning ? (
                    <p style={{ color: "red", marginBottom: "0px" }}>
                      Connect your wallet
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <>
      <div
        style={{ position: "absolute", right: "2%", top: "2%" }}
        onClick={closeModal}
      >
        <CrossIcon className="pointer position-absolute end-0 top-0" />
      </div>
      <div style={{ textAlign: "center", width: "100%" }}>
        {
          <p className="mb-0" style={{ color: "#000" }}>
            Select your NFT Avatar
          </p>
        }
        <div
          style={{
            display: "flex",
            alignItems: "center",

            margin: "auto",
            justifyContent: "space-evenly",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "780px",
              margin: "0 auto",
              overflow: "hidden",
            }}
            id="authorized-scroll"
          >
            <BsFillArrowLeftCircleFill
              onClick={scrollLEFT}
              size={30}
              color="black"
              style={{
                position: "absolute",
                top: "50%",
                left: "15px",
                transform: "translate(-50%, -50%)",
                width: "30px",
                height: "20px",
                cursor: "pointer",
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
            >
              {authorizedNfts.map((item, i) => {
                return item !== "" ? (
                  <div
                    style={{
                      margin: "5px",
                      display: "inline-block",
                      height: "40%",
                      verticalAlign: "top",
                    }}
                    key={i}
                  >
                    {/* Prevent showing unknown for null imageUrl */}
                    {item.imageUrl === null ||
                    item.imageUrl === undefined ? null : (
                      <div>
                        <img
                          ref={btnRef}
                          loading="lazy"
                          src={
                            item.imageUrl?.slice(-3) !== "mp4"
                              ? item.description === "Azuki"
                                ? formatUrl(item.imageUrl)
                                : item.imageUrl
                              : xfl
                          }
                          onError={(e) => (e.target.src = authorizedNfts[i])}
                          alt={item?.description}
                          style={{
                            width: "50px",
                            flex: "0 0 25%",
                            margin: "20px",
                            cursor: "pointer",
                            borderRadius: "9px",

                            height: "50px",
                          }}
                          onClick={(e) => {
                            handleSelected(e);
                            setErr(false);

                            handleImageDownload({
                              image_url: item?.imageUrl,
                              collection_name: item?.collectionName,
                              name: item?.name,
                              collection_token_id: item?.collectionTokenId,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                ) : null;
              })}
            </div>

            <BsArrowRightCircleFill
              onClick={scrollRIGHT}
              style={{
                position: "absolute",
                left: "100%",
                top: "50%",
                marginLeft: "-10px",
                transform: "translate(-50%, -50%)",
                width: "30px",
                height: "20px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        <div
          style={{
            background: "rgba(244, 246, 254, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {scriptURI.length === 0 ? (
            err === true ? (
              <div
                style={{
                  height: "30vh",
                  margin: "auto",
                  width: "100%",
                }}
              >
                <p style={{ color: "#000" }}>Network failed. Please retry...</p>
              </div>
            ) : (
              <LoadingOverlay
                active={loading}
                spinner={<ScaleLoader color="#fff" />}
              >
                <div
                  style={{
                    height: "300px",
                    width: "300px",
                    transform: "translate(60%, 40%)",
                  }}
                >
                  <ScaleLoader color="#aa0b22" />
                </div>
              </LoadingOverlay>
            )
          ) : (
            <div style={{ width: "100%" }}>
              <Slider
                {...sliderSettings}
                ref={(slider) => setSlider(slider)}
                className="slider2"
              >
                <div>
                  <div className={styles.swiperSlidesContainer}>
                    {scriptURI
                      ?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = scriptURI[i])}
                                loading="lazy"
                              />
                            </div>

                            {scriptURI.length === 0 ? null : (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={(e) => {
                                  saveAs(item, `Xtra${i + 1}`);
                                  toast.success(`Downloading Xtra${i + 1}...`);
                                }}
                              >
                                Download
                              </p>
                            )}
                            {/* </div> */}
                          </div>
                        );
                      })
                      .slice(0, 2)}
                    {scriptURI
                      ?.map((item, i) => {
                        return (
                          <div
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                            key={i}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                loading="lazy"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = scriptURI[i])}
                                style={{
                                  opacity: show || !!address ? "1" : "0.1",
                                }}
                              />
                            </div>
                            {show !== true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setShow(true);
                                }}
                                style={{
                                  background: "#3498db",
                                  display: !!address ? "none" : "block",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                              >
                                Tweet
                              </a>
                            ) : null}
                            {show === true || !!address ? (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  show || !!address
                                    ? (e) => {
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                              >
                                Download
                              </p>
                            ) : null}
                          </div>
                        );
                      })
                      .slice(2, 3)}
                    {scriptURI
                      ?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                loading="lazy"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = scriptURI[i])}
                                style={{
                                  opacity: discord || !!address ? "1" : "0.1",
                                }}
                              />
                            </div>
                            {show !== true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setShow(true);
                                }}
                                style={{
                                  background: "#3498db",
                                  display: !!address ? "none" : "block",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                              >
                                Tweet
                              </a>
                            ) : null}
                            {show === true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setDiscord(true);
                                }}
                                style={{
                                  background: "#7934d9",
                                  display: discord ? "none" : "block",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://discord.gg/RXh9sTKake`}
                              >
                                Discord
                              </a>
                            ) : null}
                            {discord === true || !!address ? (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  discord || !!address
                                    ? (e) => {
                                        // console.log(item);
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                              >
                                Download
                              </p>
                            ) : null}
                          </div>
                        );
                      })
                      .slice(3, 4)}
                  </div>
                </div>
                <div>
                  <div className={styles.swiperSlidesContainer}>
                    {scriptURI
                      ?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                style={{
                                  opacity: !!address ? "1" : "0.1",
                                }}
                                loading="lazy"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = scriptURI[i])}
                              />
                            </div>
                            {show !== true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setShow(true);
                                }}
                                style={{
                                  background: "#3498db",
                                  display: !!address ? "none" : "block",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                              >
                                Tweet
                              </a>
                            ) : null}
                            {show === true ? (
                              discord ? (
                                <div>
                                  <MyConnectWallet
                                    style={{
                                      background: "#7934d9",
                                      border: "none",
                                      display: !!address ? "none" : "block",
                                    }}
                                    className={`twitter-share-button ${styles.shareLink}`}
                                    icon={false}
                                    renderSecondaryBtn={false}
                                  />
                                </div>
                              ) : (
                                <a
                                  rel="noreferrer"
                                  onClick={() => {
                                    setDiscord(true);
                                  }}
                                  style={{
                                    background: "#7934d9",
                                    display: !!address ? "none" : "block",
                                  }}
                                  target="_blank"
                                  className={`twitter-share-button ${styles.shareLink}`}
                                  href={`https://discord.gg/RXh9sTKake`}
                                >
                                  Discord
                                </a>
                              )
                            ) : null}

                            {!!address ? (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  !!address
                                    ? (e) => {
                                        // console.log(item);
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                              >
                                Download
                              </p>
                            ) : null}
                          </div>
                        );
                      })
                      .slice(4, 6)}

                    {scriptURI
                      ?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                style={{
                                  opacity: !!address ? "1" : "0.1",
                                }}
                                loading="lazy"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = scriptURI[i])}
                              />
                            </div>
                            {show !== true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setShow(true);
                                }}
                                style={{
                                  background: "#3498db",
                                  display: !!address ? "none" : "block",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                              >
                                Tweet
                              </a>
                            ) : null}
                            {show === true ? (
                              discord ? (
                                <div>
                                  <MyConnectWallet
                                    style={{
                                      background: "#7934d9",
                                      border: "none",
                                      display: !!address ? "none" : "block",
                                    }}
                                    className={`twitter-share-button ${styles.shareLink}`}
                                    icon={false}
                                    renderSecondaryBtn={false}
                                  />
                                </div>
                              ) : (
                                <a
                                  rel="noreferrer"
                                  onClick={() => {
                                    setDiscord(true);
                                  }}
                                  style={{
                                    background: "#7934d9",
                                    display: !!address ? "none" : "block",
                                  }}
                                  target="_blank"
                                  className={`twitter-share-button ${styles.shareLink}`}
                                  href={`https://discord.gg/RXh9sTKake`}
                                >
                                  Discord
                                </a>
                              )
                            ) : null}

                            {!!address ? (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  !!address
                                    ? () => {
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                              >
                                Download
                              </p>
                            ) : null}
                          </div>
                        );
                      })
                      .slice(6, 7)}

                    {scriptURI
                      ?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              margin: "10px",
                              flex: "1",
                              position: "relative",
                            }}
                          >
                            <div className={styles.nftWrapper}>
                              <img
                                src={`${item}`}
                                alt="full"
                                style={{
                                  opacity: !!address ? "1" : "0.1",
                                }}
                                loading="lazy"
                                className={styles.nftImage}
                                onError={(e) => (e.target.src = scriptURI[i])}
                              />
                            </div>
                            {show !== true ? (
                              <a
                                rel="noreferrer"
                                onClick={() => {
                                  setShow(true);
                                }}
                                style={{
                                  background: "#3498db",
                                  display: !!address ? "none" : "block",
                                }}
                                target="_blank"
                                className={`twitter-share-button ${styles.shareLink}`}
                                href={`https://twitter.com/intent/tweet?text=${"Just got an XTRAordinary drip for my NFTs from @0xXtra \nVisit: app.0xXtra.com to get yours"}`}
                              >
                                Tweet
                              </a>
                            ) : null}
                            {show === true ? (
                              discord ? (
                                <div>
                                  <MyConnectWallet
                                    style={{
                                      background: "#7934d9",
                                      border: "none",
                                      display: !!address ? "none" : "block",
                                    }}
                                    className={`twitter-share-button ${styles.shareLink}`}
                                    icon={false}
                                    renderSecondaryBtn={false}
                                  />
                                </div>
                              ) : (
                                <a
                                  rel="noreferrer"
                                  onClick={() => {
                                    setDiscord(true);
                                  }}
                                  style={{
                                    background: "#7934d9",
                                    display: !!address ? "none" : "block",
                                  }}
                                  target="_blank"
                                  className={`twitter-share-button ${styles.shareLink}`}
                                  href={`https://discord.gg/RXh9sTKake`}
                                >
                                  Discord
                                </a>
                              )
                            ) : null}

                            {!!address ? (
                              <p
                                style={{
                                  color: "#027A48",
                                  cursor: "pointer",
                                  display: "block",
                                  marginBottom: "0px",
                                }}
                                onClick={
                                  !!address
                                    ? () => {
                                        saveAs(item, `Xtra${i + 1}`);
                                        toast.success(
                                          `Downloading Xtra${i + 1}...`
                                        );
                                      }
                                    : null
                                }
                              >
                                Download
                              </p>
                            ) : null}
                          </div>
                        );
                      })
                      .slice(7, 8)}
                  </div>
                </div>
              </Slider>

              <div className="d-flex justify-content-between align-items-center px-2 pb-2">
                <div className="pointer" onClick={previousSlide}>
                  <LeftArrowIcon />
                </div>
                <div className="pointer" onClick={nextSlide}>
                  <RightArrowIcon />
                </div>
              </div>
            </div>
          )}
        </div>

        <div style={{ marginTop: "20px" }}>
          {isConnected ? (
            // The magic blocker for the minting process -  address === inputValue
            <button
              className="btn"
              style={{
                color: "#fff",
                backgroundColor:
                  publicSale || address === inputValue ? "#aa0b22" : "grey",
                cursor:
                  publicSale || address === inputValue
                    ? "pointer"
                    : "not-allowed",
              }}
              onClick={
                publicSale || address === searchedAddress ? openTheModal : null
              }
              disabled={!publicSale && address !== searchedAddress}
            >
              Mint XTRA Jackets
            </button>
          ) : (
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button
                            onClick={openConnectModal}
                            type="button"
                            className="btn "
                            style={{
                              color: "#fff",
                              backgroundColor:
                                scriptURI.length === 0 ? "grey" : "#aa0b22",
                              cursor:
                                scriptURI.length === 0
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            Connect Wallet to Mint
                          </button>
                        );
                      }
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
          )}

          {!isConnected && !publicSale && (
            <div
              style={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                marginTop: "10px",
              }}
            >
              <CheckIcon />
              <p
                style={{
                  color: "#AAAAAA",
                  display: !!address ? "none" : "block",
                  marginBottom: "0px",
                }}
              >
                Connect wallet to verify ownership and unlock rare Jacket
                Designs
              </p>
            </div>
          )}

          {/* Error message when connecyted address is not same as input address */}

          {!!address && !publicSale ? (
            searchedAddress !== address ? (
              <div
                style={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: "center",
                }}
              >
                <AiOutlineWarning size={20} color="red" />
                <p style={{ color: "red" }}>
                  Connected wallet is not same as input address.
                </p>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AuthorizedNFTModal;
