import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { readContract } from "@wagmi/core";
import axios from "axios";

// Modal
import Modal from "react-modal";
import MintModal from "../MintModals/MintModal";

import { ScaleLoader } from "react-spinners";
import { useAccount } from "wagmi";

import xfl from "../../../assets/img/core-img/xfl.png";

import LoadingOverlay from "react-loading-overlay";

// dummy images
import dm1 from "../../../assets/img/core-img/1.png";
import dm2 from "../../../assets/img/core-img/2.png";
import dm3 from "../../../assets/img/core-img/3.png";
import dm4 from "../../../assets/img/core-img/4.png";
import dm5 from "../../../assets/img/core-img/5.png";
import dm6 from "../../../assets/img/core-img/6.png";
import dm7 from "../../../assets/img/core-img/7.png";
import dm8 from "../../../assets/img/core-img/8.png";

import {
  desktopModalStyles,
  dummyImagesNames,
  formatUrl,
  loaderStyles,
  mobileModalStyles,
} from "../../../constants/userNftModal";
import useMediaQuery from "../../../hooks/useMediaQuery";

import EmptyAuthorizedNFTModal from "./EmptyAuthorizedNFTModal";
import AuthorizedNFTModal from "./AuthorizedNFTModal";
import { contractConfig } from "../../../constants/mintModal";

LoadingOverlay.propTypes = undefined;

Modal.setAppElement("#root");

const dummyImages = [dm1, dm2, dm3, dm4, dm5, dm6, dm7, dm8];

const UserNFTModal = ({
  handleUserModal,
  modalIsOpen,
  closeModal,
  authorizedNfts,
  loading,
  inputValue,
  searchedAddress,
  err,
  setErr,
  modalMobileIsOpen,
}) => {
  const [modalMintOpen, setMintOpen] = useState(false);
  const [modalMintOpenMobile, setMintOpenMobile] = useState(false);
  const [selected, setSelected] = useState("");
  const [scriptURI, setScriptURI] = useState([]);
  const [show, setShow] = useState(false);
  const [discord, setDiscord] = useState(false);
  const [publicSale, setPublicSale] = useState(false);
  const [warning, setWarning] = useState(false);
  const [process, setProcess] = useState(false);
  const [error, setError] = useState(null);

  // The address and account details from wagmi
  const { address, isConnected, connector } = useAccount();

  let btnRef = useRef();

  const { isMobile } = useMediaQuery();

  useLayoutEffect(() => {
    const isMatched = localStorage.getItem(address);

    if (isMatched != null && typeof isMatched != "undefined") {
      localStorage.setItem("addressMatched", "yes");
    } else {
      localStorage.setItem("addressMatched", "no");
    }

    const validateAddress = localStorage.getItem("addressMatched");

    if (validateAddress === "yes") {
      setError(true);
    } else {
      setError(false);
    }
  }, [address]);
  // const refreshPage = () => window.location.reload(true);

  // useEffect(() => {
  //   const handleConnectorUpdate = ({ account, chain }) => {
  //     if (account && modalIsOpen) {
  //       refreshPage();
  //     } else if (chain && modalIsOpen) {
  //       refreshPage();
  //     }
  //   };

  //   if (connector) {
  //     connector.on("change", handleConnectorUpdate);
  //   }

  //   return () => connector?.off("change", handleConnectorUpdate);
  // }, [connector, modalIsOpen]);

  useLayoutEffect(() => {
    if (authorizedNfts.length > 0) {
      const newData = {
        image_url: authorizedNfts[0]?.imageUrl,
        collection_name: authorizedNfts[0]?.collectionName,
        name: authorizedNfts[0]?.name,
        collection_token_id: authorizedNfts[0]?.collectionTokenId,
      };
      getScriptURI(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizedNfts]);

  useEffect(() => {
    const getIsPublicSale = async () => {
      const publicSale = await readContract({
        ...contractConfig,
        functionName: "publicSale",
      });
      setPublicSale(publicSale);
    };
    getIsPublicSale();
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (modalMintOpen || modalMintOpenMobile) {
        if (!publicSale) {
          const newData = {
            image_url: authorizedNfts[0]?.imageUrl,
            collection_name: authorizedNfts[0]?.collectionName,
            name: authorizedNfts[0]?.name,
            collection_token_id: authorizedNfts[0]?.collectionTokenId,
          };
          getScriptURI(newData);
        }
      }
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptURI, authorizedNfts]);

  const getScriptURI = async (clicked) => {
    const formatClickedNFT = {
      ...clicked,
      image_url:
        clicked?.collection_name === "Azuki"
          ? formatUrl(clicked?.image_url)
          : clicked?.image_url,
    };

    const data = new FormData();
    data.append(
      "file",
      clicked?.collection_name === "Azuki"
        ? formatUrl(clicked?.image_url)
        : clicked?.image_url
    );
    data.append("upload_preset", "t5rqdfqg");
    data.append("cloud_name", "dfiqjwdgd");

    const validDN = dummyImagesNames.includes(clicked?.collection_name);

    let theResult;

    try {
      if (
        clicked?.image_url?.slice(-3) === "mp4" ||
        clicked?.image_url?.slice(-3) === "gif"
      ) {
        setScriptURI(dummyImages);
      } else if (validDN === false && clicked?.image_url.slice(-3) !== "mp4") {
        const val = localStorage.getItem(clicked?.image_url);
        if (!val) {
          const baseURI =
            "https://xtra-dapp.herokuapp.com/api/generate-nft-image/";

          const config = {
            method: "Post",
            url: baseURI,
            headers: {
              Authorization: "",
              "Content-Type": "application/json",
            },
            data: validDN ? null : formatClickedNFT,
          };

          await axios(config).then((res) => {
            theResult = res.data.data;
          });

          localStorage.setItem(searchedAddress, `${JSON.stringify(theResult)}`);

          setScriptURI(theResult);

          localStorage.setItem(
            clicked?.image_url,
            `${JSON.stringify(theResult)}`
          );
        } else {
          setScriptURI(JSON.parse(val));
        }
      } else {
        localStorage.setItem(searchedAddress, `${JSON.stringify(dummyImages)}`);
        setScriptURI(dummyImages);
      }
    } catch (error) {
      console.log(error);
      setErr(true);
    }
  };

  function openMintModal() {
    setMintOpenMobile(true);
    setMintOpen(true);

    setScriptURI([]);
  }

  function openTheModal() {
    if (address === undefined) {
      setWarning(true);
    }
    setTimeout(() => {
      setWarning(false);
    }, 5000);

    openMintModal();
  }

  const handleSelected = (e) => {
    setProcess(true);
    setScriptURI([]);
    setSelected(e.target.currentSrc);
  };

  function closeMintModal() {
    setMintOpenMobile(false);

    setMintOpen(false);
  }

  const scrollLEFT = (e) => {
    e.preventDefault();

    const content = document.getElementById("authorized-scroll");

    const scrollStep = 100;

    let sl = content.scrollLeft,
      cw = content.scrollWidth;

    if (sl - scrollStep <= 0) {
      content.scrollTo(0, 0);
    } else {
      content.scrollTo(sl - scrollStep, 0);
    }
  };

  const scrollRIGHT = (e) => {
    e.preventDefault();

    const content = document.getElementById("authorized-scroll");
    const scrollStep = 100;

    let sl = content.scrollLeft,
      cw = content.scrollWidth;

    if (sl + scrollStep >= cw) {
      content.scrollTo(cw, 0);
    } else {
      content.scrollTo(sl + scrollStep, 0);
    }
  };

  const handleImageDownload = async (theValue) => {
    await getScriptURI(theValue);
  };

  return (
    <>
      {loading ? (
        <Modal
          isOpen={modalMobileIsOpen || modalIsOpen}
          onRequestClose={closeModal}
          style={loaderStyles}
          shouldCloseOnOverlayClick={false}
          className="position-absolute top-50 start-50 translate-middle"
        >
          <LoadingOverlay
            active={loading}
            spinner={<ScaleLoader color="#fff" />}
          >
            <div
              style={{
                height: isMobile ? "250px" : "300px",
                width: isMobile ? "250px" : "300px",
              }}
            ></div>
          </LoadingOverlay>
        </Modal>
      ) : authorizedNfts.length === 0 ? (
        <Modal
          isOpen={modalMobileIsOpen || modalIsOpen}
          onRequestClose={closeModal}
          style={isMobile ? mobileModalStyles : desktopModalStyles}
          shouldCloseOnOverlayClick={false}
          className="position-absolute top-50 start-50 translate-middle"
        >
          <EmptyAuthorizedNFTModal
            modalMobileIsOpen={modalMobileIsOpen}
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            dummyImages={dummyImages}
            address={address}
            show={show}
            setShow={setShow}
            discord={discord}
            setDiscord={setDiscord}
            scriptURI={scriptURI}
            isMobile={isMobile}
            publicSale={publicSale}
            openMintModal={openMintModal}
            isConnected={isConnected}
          />
        </Modal>
      ) : (
        <Modal
          isOpen={modalMobileIsOpen || modalIsOpen}
          onRequestClose={closeModal}
          style={isMobile ? mobileModalStyles : desktopModalStyles}
          shouldCloseOnOverlayClick={false}
          className="position-absolute top-50 start-50 translate-middle"
        >
          <AuthorizedNFTModal
            closeModal={closeModal}
            address={address}
            show={show}
            setShow={setShow}
            discord={discord}
            setDiscord={setDiscord}
            scriptURI={scriptURI}
            isMobile={isMobile}
            scrollLEFT={scrollLEFT}
            authorizedNfts={authorizedNfts}
            btnRef={btnRef}
            formatUrl={formatUrl}
            setErr={setErr}
            setProcess={setProcess}
            setScriptURI={setScriptURI}
            handleImageDownload={handleImageDownload}
            err={err}
            loading={loading}
            isConnected={isConnected}
            searchedAddress={searchedAddress}
            inputValue={inputValue}
            warning={warning}
            handleSelected={handleSelected}
            xfl={xfl}
            setSelected={setSelected}
            scrollRIGHT={scrollRIGHT}
            openTheModal={openTheModal}
            publicSale={publicSale}
          />
        </Modal>
      )}

      <MintModal
        modalMintOpen={modalMintOpen}
        closeMintModal={closeMintModal}
        closeUserModal={closeModal}
        userModalIsOpen={handleUserModal}
        openTheModal={setMintOpen}
        searchedAddress={searchedAddress}
        publicSale={publicSale}
        modalMintOpenMobile={modalMintOpenMobile}
      />
    </>
  );
};

export default UserNFTModal;
