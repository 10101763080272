import React from "react";

const RightArrowIcon = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7599 36.7732C29.891 36.7732 37.2932 29.371 37.2932 20.2399C37.2932 11.1088 29.891 3.70654 20.7599 3.70654C11.6288 3.70654 4.22656 11.1088 4.22656 20.2399C4.22656 29.371 11.6288 36.7732 20.7599 36.7732Z"
        stroke="#979797"
        strokeOpacity="0.5"
        strokeWidth="2.48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7607 26.8531L27.3741 20.2398L20.7607 13.6265"
        stroke="#979797"
        strokeOpacity="0.5"
        strokeWidth="2.48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1465 20.2395H27.3731"
        stroke="#979797"
        strokeOpacity="0.5"
        strokeWidth="2.48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightArrowIcon;
