import SectionHeading from "../SectionHeading";
import bgLeft from "../../assets/img/bg-img/rightcollect.png";
import bgRight from "../../assets/img/bg-img/leftcollect.png";

const DiscoverOurNewCollection = ({
  ClassItem = "collection section-padding-0",
  data,
}) => {
  return (
    <section
      className={ClassItem}
      style={{
        paddingBottom: "100px",
        background: "#000",
      }}
    >
      <div className="container">
        <SectionHeading
          ClassTitle="gradient-t"
          title={data.SectionHeading.title}
          text={data.SectionHeading.text}
        />
        <div className="row text-center " style={{ position: "relative" }}>
          <div style={{ position: "absolute" }}>
            <img src={bgLeft} alt="left-bg" />
          </div>
          {data?.list?.map((item, key) => (
            <div className="col mb-20" key={key}>
              <div className="single_collectio">
                <div className="collection-img">
                  <h1 style={{ color: "#fff" }}>{item.img}</h1>
                </div>
                <div className="collection-info">
                  <h5 style={{ color: "#AA0B22" }}>{item.title}</h5>
                </div>
              </div>
            </div>
          ))}
          <div style={{ position: "absolute", right: "10%", bottom: "5%" }}>
            <img src={bgRight} alt="right-bg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DiscoverOurNewCollection;
