import React, { useEffect, useState } from "react";
import jacketVideo from "../../../assets/img/core-img/About-the-Drop.gif";
// Modal
import Modal from "react-modal";
import { OutcomeModal } from "../OutcomeModal/OutcomeModal";
import {
  useAccount,
  useContractWrite,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import { AiOutlineMenu } from "react-icons/ai";
import { readContracts } from "@wagmi/core";
import {
  contractConfig,
  mintDesktopStyle,
  mintMobileStyle,
  MINT_KEY,
} from "../../../constants/mintModal";

import { useGetTokenNumber } from "../../../queries/tokenNumber";
import MintModalMobile from "./MintModalMobile";
import useMediaQuery from "../../../hooks/useMediaQuery";
import CrossIcon from "../../../assets/icons/CrossIcon";
import { useApproveTransaction } from "../../../queries/approveTransaction";

Modal.setAppElement("#root");

const MintModal = ({
  closeMintModal,
  modalMintOpen,
  closeUserModal,
  userModalIsOpen,
  searchedAddress,
  modalMintOpenMobile,
  publicSale,
}) => {
  const [counter, setCounter] = useState(1);
  const [outcomeModal, setOutcomeModal] = useState(false);
  const [outcomeModalMobile, setOutcomeModalMobile] = useState(false);
  const [loader, setLoader] = useState(false);
  const [maxMintWarning, setMaxMintWarning] = useState(false);
  const [mintPrice, setMintPrice] = useState(null);
  const [mintLimit, setMintLimit] = useState(null);
  const [userMintTokenBalance, setUserMintTokenBalance] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [onPM, setOnPM] = useState(false);
  const [onMobilePM, setOnMobilePM] = useState(false);
  const [mintId, setMintId] = useState("");
  const [authToken, setAuthToken] = useState("");
  // The address and account details from wagmi
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { isLoading: switchingNetwork, switchNetwork } = useSwitchNetwork();
  const { isMobile } = useMediaQuery();
  const isConnected = !!address;

  useEffect(() => {
    if (document.body.style.overflow === "unset") {
      document.body.style.overflow = "hidden";
    }
  }, []);

  const getMintLimitTokenBalanceMintPrice = async () => {
    const data = await readContracts({
      contracts: [
        {
          ...contractConfig,
          functionName: "maxUserMintLimit",
        },
        {
          ...contractConfig,
          functionName: "userToTokensMinted",
          args: [address],
        },
        {
          ...contractConfig,
          functionName: "whitelistFee",
        },
      ],
    });
    setMintLimit(Number(data[0]));
    setUserMintTokenBalance(Number(data[1]));
    setMintPrice(Number(data[2]));
  };

  useEffect(() => {
    getMintLimitTokenBalanceMintPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const maxMintReached = userMintTokenBalance >= mintLimit;

  const totalMintPrice = mintPrice * counter;

  const disableMint = () => {
    if (maxMintWarning || maxMintReached) return "Max mint reached";
    if (!publicSale && searchedAddress !== address)
      return "Connected wallet is not same as searched address";
    return "";
  };

  const {
    data,
    error,
    isError,
    write: mint,
  } = useContractWrite({
    ...contractConfig,
    functionName: "whitelistMint",
    overrides: { from: address, value: totalMintPrice.toString() },
  });
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const { mutateAsync: getTokenNumber } = useGetTokenNumber((data) => {
    mint({
      recklesslySetUnpreparedArgs: [counter, MINT_KEY, data.generatedNumber],
    });
    setMintId(data.mintId);
    setAuthToken(data.authToken);
  });

  //  Handles the increase and decrease of mint value
  const handleIncreaseMintCount = async () => {
    const maxIncrease = mintLimit - userMintTokenBalance;
    if (counter < maxIncrease) {
      setCounter((counter) => counter + 1);
    } else {
      setMaxMintWarning(true);
    }
  };

  const handleDecreaseMintCount = () => {
    setMaxMintWarning(false);

    if (counter > 1) {
      setCounter((counter) => counter - 1);
    }
  };

  const { mutateAsync: approveTransaction } = useApproveTransaction();
  const approveMintTransaction = async (isSuccess) => {
    try {
      await approveTransaction({
        mintId: mintId,
        transactionStatus: isSuccess,
        authToken: authToken,
      });
      setAuthToken("");
      setMintId("");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isError || isSuccess) {
      approveMintTransaction(isSuccess);
      setLoader(false);
      getMintLimitTokenBalanceMintPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  // handles the mint action
  const handleMint = async () => {
    // eslint-disable-next-line eqeqeq
    if (chain?.id != 1) {
      switchNetwork?.(1);
    } else {
      try {
        setLoader(true);
        openTheModal();
        await getTokenNumber({ amount_to_mint: counter });
      } catch (error) {
        setLoader(false);
        setErrorMessage(error?.response?.data?.responseMessage);
      }
    }
  };

  // Handles the success or failure of a mint
  function openTheModal() {
    setOutcomeModalMobile(true);

    setOutcomeModal(true);
  }

  function closeOutcomeModal() {
    setOutcomeModalMobile(false);
    setOutcomeModal(false);
  }

  const openPostModal = () => {
    setOnPM(true);
    setOnMobilePM(true);

    closeUserModal();
    closeMintModal();
  };

  const closeMintAndGetLastAddress = () => {
    closeMintModal();
    if (searchedAddress !== undefined || searchedAddress !== null) {
      const lastSearched = localStorage.getItem(
        `lastSearched${searchedAddress}`
      );
      const sv = localStorage.getItem(searchedAddress);

      console.log(sv);
      console.log(lastSearched);
    }
  };

  const isMintDisable =
    isLoading || switchingNetwork || !isConnected || !publicSale
      ? searchedAddress !== address
      : null;

  const mintBtnText = () => {
    if (isConnected && chain?.id !== 1) return "Click to switch network";
    if (isConnected && maxMintReached) return "View your NFTs";
    if (isConnected && !maxMintReached) return "Mint";
    return "Connect wallet to Mint";
  };

  return (
    <div>
      <Modal
        isOpen={modalMintOpen || modalMintOpenMobile}
        onRequestClose={closeMintAndGetLastAddress}
        style={isMobile ? mintMobileStyle : mintDesktopStyle}
        shouldCloseOnOverlayClick={false}
        className="position-absolute overflow-auto bg-white top-50 start-50 translate-middle"
      >
        {isMobile ? (
          <MintModalMobile
            closeMintAndGetLastAddress={closeMintAndGetLastAddress}
            maxMintWarning={maxMintWarning}
            handleDecrease={handleDecreaseMintCount}
            counter={counter}
            handleIncrease={handleIncreaseMintCount}
            handleMint={handleMint}
            maxMintReached={maxMintReached}
            isLoading={isLoading}
            totalMintPrice={totalMintPrice}
            isConnected={isConnected}
            disableMint={disableMint}
            isMintDisable={isMintDisable}
            searchedAddress={searchedAddress}
            openPostModal={openPostModal}
            publicSale={publicSale}
            mintBtnText={mintBtnText}
          />
        ) : (
          <>
            <div
              style={{ position: "absolute", right: "2%", top: "2%" }}
              onClick={closeMintAndGetLastAddress}
            >
              <CrossIcon className="pointer" />
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <div
                className="position-relative"
                style={{
                  background: "rgba(244, 246, 254, 0.8)",
                  width: "55%",
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  draggable="false"
                  src={jacketVideo}
                  className="center-block img-responsive phone-img"
                  alt="xtra jackets"
                />
              </div>

              <div
                style={{
                  width: "43%",
                  padding: "40px 10px 40px 30px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <p
                    style={{
                      color: "#000",
                      fontWeight: "700",
                      lineHeight: "1.2",
                      fontSize: "18px",
                    }}
                  >
                    Welcome to the randomized mint of XTRA Jackets with 8
                    different rarities
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <h3
                    style={{
                      color: "#000",
                      marginTop: "10px",
                      marginBottom: "0px",
                    }}
                  >
                    Perks
                  </h3>

                  <ul
                    style={{
                      marginTop: "5px",
                      marginBottom: "2rem",
                    }}
                  >
                    <li className="m-1" style={{ listStyle: "inside" }}>
                      Drip your NFT avatars
                    </li>
                    <li className="m-1" style={{ listStyle: "inside" }}>
                      Access 3D files
                    </li>
                    <li className="m-1" style={{ listStyle: "inside" }}>
                      Own the IP + Forging Rights
                    </li>
                  </ul>

                  <div>
                    <h6 style={{ color: "red", fontSize: "12px" }}>
                      {disableMint()}
                    </h6>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <button
                      style={{
                        flex: "1",
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                        borderRadius: "10px",
                        color: "#979797",
                      }}
                      onClick={handleDecreaseMintCount}
                    >
                      -
                    </button>
                    <div
                      style={{
                        flex: "3",
                        background: "#F2F2F2",
                        marginRight: ".5rem",
                        marginLeft: ".5rem",
                        textAlign: "center",
                        padding: "5px",
                      }}
                    >
                      {counter}
                    </div>
                    <button
                      style={{
                        flex: "1",
                        border: "none",
                        outline: "none",
                        color: "#979797",

                        borderRadius: "10px",
                        cursor: maxMintWarning ? "not-allowed" : "pointer",
                      }}
                      onClick={handleIncreaseMintCount}
                    >
                      +
                    </button>
                  </div>

                  <p
                    style={{
                      fontSize: "10px",
                      color: "black",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>
                      Price:  {totalMintPrice} ETH
                    </span>
                    <AiOutlineMenu size={13} color={"black"} />
                  </p>

                  <button
                    className="btn active"
                    style={{
                      color: "#fff",
                      backgroundColor:
                        !publicSale && searchedAddress !== address
                          ? "grey"
                          : "#aa0b22",
                    }}
                    disabled={isMintDisable}
                    onClick={() =>
                      maxMintReached ? openPostModal() : handleMint()
                    }
                  >
                    {mintBtnText()}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>

      <div>
        <OutcomeModal
          closeOutcomeModal={closeOutcomeModal}
          outcomeModal={outcomeModal}
          outcomeModalMobile={outcomeModalMobile}
          txStatus={isLoading}
          loader={loader}
          txHash={data?.hash}
          closeUserModal={closeUserModal}
          closeMintModal={closeMintModal}
          userModalIsOpen={userModalIsOpen}
          rejected={isError}
          error={error}
          isSuccess={isSuccess}
          errorMessage={errorMessage}
          onPM={onPM}
          setOnPM={setOnPM}
          onMobilePM={onMobilePM}
          setOnMobilePM={setOnMobilePM}
        />
      </div>
    </div>
  );
};

export default MintModal;
