const SectionHeading = ({
  ClassTitle = "",
  title,
  otherTitle,
  text,
  addWhite = false,
}) => {
  return (
    <div className="section-heading text-center ">
      {/* Dream Dots */}
      <div
        className="dream-dots justify-content-center z-index-1"
        data-aos="fade-up"
      >
        <span className={ClassTitle}>{title}</span>
      </div>
      <h2 data-aos="fade-up" style={{ color: "white" }}>
        {otherTitle}
      </h2>
      <p data-aos="fade-up" style={{ color: "white" }}>
        {text}
      </p>
    </div>
  );
};

export default SectionHeading;
