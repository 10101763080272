export const desktopModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    borderRadius: "10px",
    overflowX: "hidden",
    backgroundColor: "#fff",
    outline: "none",
    padding: "15px",
    margin: "55px 0px 0px",
    maxHeight: "85vh",
    width: "880px",
    overflowY: "auto",
  },
};

export const mobileModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    borderRadius: "10px",
    overflowX: "hidden",
    overflowY: "scroll !important",
    width: "90vw",
    marginTop: "15px",
    height: "70vh",
    backgroundColor: "#fff",
    outline: "none",
    padding: "15px",
  },
};

export const loaderStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    borderRadius: "10px",
    outline: "none",
    padding: "10px",
    backgroundColor: "#fff",
  },
};

export const dummyImagesNames = [
  "sandbox",
  // "veefriends", --- These collection name is called "RightwayToken"
  "RightwayToken",
  "Xtra for Lions",
  "Alpha Card",
  "CryptoPunksMarket",
  "Sandbox's LANDs",
  "XTRA Fantasy Pyramid",
  "Meebits", // included
];

export const formatUrl = (url) => {
  const oldUrl = url?.slice(0, 30);
  const ipfsUrl = "https://ipfs.io/";
  const newLink = url?.replace(oldUrl, ipfsUrl);

  return newLink;
};
