import { useMutation } from "@tanstack/react-query";
import httpClient from "../services/httpClient";

export const useGetTokenNumber = (onSuccess) => {
  return useMutation((data) =>
    httpClient.post("/get-token-number", data).then((res) => {
      if (onSuccess) {
        onSuccess(res.data.data);
      }
      return res.data.data;
    })
  );
};
