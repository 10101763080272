import SectionHeading from "../SectionHeading";
import team1 from ".././../assets/img/team-img/Paras-Batra.png";
import team2 from ".././../assets/img/team-img/Ayush.png";
import { Link } from "react-router-dom";

const TeamArea = ({ data }) => {
  const TeamProfile = [
    {
      icons: ["fa fa-twitter"],
      img: team1,
      name: "@paras404",
      title: "metaverse stylist, storyteller",
    },
    {
      icons: ["fa fa-twitter"],
      img: team2,
      name: "@nogods404",
      title: "nft irl, design geek",
    },
  ];

  return (
    <section className="our_team_area section-padding-0-70 clearfix" id="team">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionHeading
              ClassTitle="gradient-text"
              title={data.SectionHeading.title}
              text={data.SectionHeading.text}
            />
          </div>
        </div>
        <div className="row  align-items-center justify-content-center ">
          {/* Single Team Member */}
          {TeamProfile.map((item, key) => (
            <div className="col-lg-3 col mb-30" key={key}>
              <div className="team-item">
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="socials d-inline-block">
                    {item.icons.map((itemIcon, key) => (
                      <Link
                        key={key}
                        to={`https://twitter.com/${item.name}`}
                        target="_blank"
                        className={itemIcon}
                      ></Link>
                    ))}
                  </span>
                  <span className="img-holder d-inline-block">
                    <img src={item.img} alt="Team" />
                  </span>
                </div>
                <div className="team-content">
                  <h5 className="mb-2">{item.name}</h5>
                  <p
                    style={{ fontSize: "10px" }}
                    className="text-uppercase mb-0"
                  >
                    {item.title}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamArea;
