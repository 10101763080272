import { useState, useEffect } from "react";
import SectionHeading from "../SectionHeading";
import discover from "../../assets/img/icons/i1.png";
import wallet from "../../assets/img/icons/shirt.png";
import shine from "../../assets/img/icons/i3.png";

const HowItWorks = ({ ClassItem, data }) => {
  const newList = [
    {
      num: "Step 01",
      img: discover,
      title: "Enter Address",
      text: "Enter Ethereum wallet address or ENS that has the supporting NFTs",
    },
    {
      num: "Step 02",
      img: wallet,
      title: "Make NFTs Fashionable",
      text: "Choose the NFT. Complete tasks to unlock more designs",
    },
    {
      num: "Step 03",
      img: shine,
      title: "Verify and Mint",
      text: "Connect the wallet and mint 3D Jacket NFT. Max 10 mints per wallet",
    },
  ];

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <section className={ClassItem}>
      <div className="container">
        <SectionHeading
          title={data.SectionHeading.title}
          // text={data.SectionHeading.text}
        />
        <div className="row w-lg-0 w-100 m-lg-0 m-auto">
          {newList.map((item, key) => (
            <div className="col-12 col-md-6 col-lg-4" key={key}>
              {/* Content */}
              <div
                className="service_single_content v2 text-lg-left text-center "
                data-wow-delay="0.2s"
              >
                {isMobile !== true ? (
                  <div className=" mw-lg-0 mw-100 w-100 m-auto">
                    <div className="w-50 step-num">{item.num}</div>
                  </div>
                ) : (
                  <div className="mw-100 p-auto m-auto position-relative">
                    <div
                      className="w-50 px-3 mb-5 p-3 m-auto position-absolute fixed-top top-100 left-50"
                      style={{
                        borderRadius: "30px",
                        backgroundColor: "rgba(244, 246, 254, 0.8)",
                      }}
                    >
                      {item.num}
                    </div>
                    <div></div>
                  </div>
                )}
                {/* Icon */}
                <div className="service_icon mt-100 mr-5">
                  <img draggable="false" src={item.img} alt="" />
                </div>
                <h6 style={{ color: "#fff" }}>{item.title}</h6>
                <p style={{ color: "#828098" }}>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
