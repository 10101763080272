import { useRoutes } from "react-router";
// import About from "../pages/About";
import Collection from "../pages/Collection";
import Contact from "../pages/Contact";
import FAQ from "../pages/FAQ";
import HomeDemo1 from "../pages/HomeDemo1";
import PageNotFound from "../pages/404Page";
import { Navigate } from "react-router-dom";

const Routes = () => {
  return useRoutes([
    {
      path: "/",
      element: <HomeDemo1 />,
    },
    // { path: "/about-us", element: <About /> },
    { path: "/faq", element: <FAQ /> },
    { path: "/collection", element: <Collection /> },
    { path: "/terms&condition", element: <Contact /> },
    { path: "/404", element: <PageNotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
};

export default Routes;
